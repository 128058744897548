import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import "./index.css";
import arrow from "../../../assets/ArrowsIcons/arrow_right_blue.svg";
import confidentialtransactionImg from "../../../assets/GeneralIcons/confidential_transaction.svg";
import FormatAmount from "../../_utils/FormatAmount/FormatAmount";
import formatDate from "../../../utils/formatDateViewAll";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

const InternalTxnDataCard = ({
  amount,
  beneficiary_account_ifsc_code,
  owner_account_ifsc_code,
  benAccNo,
  ownerAccNo,
  confidentialTransaction,
  createdOn,
  transactionId,
  hasConfidential,
  windowWidth,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="internalTxn-card"
      onClick={() => {
        // if (windowWidth <= 1200) {
        //   toast.info("Feature coming soon...", toast_position);
        //   return;
        // }
        navigate(`/transaction/internal_transaction/details/${transactionId}`);
      }}
    >
      <div className="card_hover_overlay"></div>
      <div className="internalTxn-card-first">
        <h5>
          ₹ <FormatAmount price={amount} />
        </h5>
        <div className="w-[130px] flex justify-end text-right gap-[10px] leading-[14px]">
          <p>{formatDate(new Date(createdOn), false)}</p>
          {confidentialTransaction === "Y" && hasConfidential && (
            <img
              className="h-[18px] w-[18px]"
              src={confidentialtransactionImg}
              alt=""
            />
          )}
        </div>
      </div>
      <div className="internalTxn-card-second">
        <img
          src={require("../../../assets/IfscLogo/" +
            IfscCodeMapper(owner_account_ifsc_code))}
          alt=""
          className="beneBank-img"
        />
        <div className="acc-nos">
          <p> {"•••• " + ownerAccNo?.slice(-4)} </p>
          <img src={arrow} alt="" />
          <p> {"•••• " + benAccNo?.slice(-4)} </p>
        </div>

        <img
          src={require("../../../assets/IfscLogo/" +
            IfscCodeMapper(beneficiary_account_ifsc_code))}
          alt=""
          className="ownerBank-img"
        />
      </div>
    </div>
  );
};

export default InternalTxnDataCard;
