import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, IconButton, Tooltip, tooltipClasses } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkUsertoken } from "../../../redux/features/login/login";
import { internalSingleApproval } from "../../../redux/features/internalTransaction/internalTransactionSingleApproval.js";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import InternalTxnMultiApproval from "../../../components/InternalTransactionMain/InternalTxnMultiApproval";
import BeneTxnFilter from "../../../components/BeneTransaction/BeneTxnFilter";
import CloseBtn from "../../../assets/CloseIcons/close_with_blue_circle.svg";
import FiterNoAccount from "../../../assets/Graphics/No transaction found.svg";
import GridBlue from "../../../assets/ViewIcons/Grid view blue.svg";
import GridWhite from "../../../assets/ViewIcons/Grid view white.svg";
import LeftSign from "../../../assets/arrow_left_white.svg";
import ListBlue from "../../../assets/ViewIcons/list_view_blue.svg";
import ListWhite from "../../../assets/ViewIcons/list_view_white.svg";
import NextIcon from "../../../assets/ArrowsIcons/arrow_right_white_circle.svg";
import NoAccountFound from "../../../assets/Graphics/No account remaining to approved.svg";
import Selected from "../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import SelectWhite from "../../../assets/CheckmarksIcons/select_all_with_white_outlined_circle.svg";
import SelectCategoryTransaction from "../../../components/_utils/SelectCategoryTransaction";
import Xpentraloader from "../../../assets/Beneficiary Icons/inputloder.gif";

const InternalTransactionMultiApproval = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initCategory, setInitCategory] = useState("");
  const [toggle, setToggle] = useState(true);
  const [label, setlabel] = useState("Select category");
  const [filterOpen, setFilterOpen] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [page, setPage] = useState(1);
  const [selectAllCard, setselectAllCard] = useState(false);
  const [allBeneTxnAccountsLoading, setAllBeneTxnAccountsLoading] =
    useState(false);
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 250 : window.innerWidth >= 2560 ? 102 : 50
  );
  const [approvedAccounts, setApprovedAccounts] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedSendTransactions, setSelectedSendTransactions] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [ShowFilterErrorPage, setShowFilterErrorPage] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);
  const [isMobileResponsive, setIsMobileResponsive] = useState(
    window.innerWidth <= 800
  );
  const [widthCheck, setIswidthCheck] = useState(window.innerWidth <= 950);
  const filterRef = useRef(null);
  const { internalTxnSingleApprovalData } = useSelector(
    (state) => state.internalTxnSingleApproval
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileResponsive(window.innerWidth <= 800);
      setIswidthCheck(window.innerWidth <= 950);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    dispatch(checkUsertoken());
    if (label === "Select category") {
      if (page === 1) {
        setAllBeneTxnAccountsLoading(true);
      }
      dispatch(
        internalSingleApproval({
          page_no: page,
          page_size: pageSize,
        })
      )
        .then((response) => {
          const newData =
            response?.payload?.transactions?.map((account) => ({
              ...account,
              loading: false,
              error: "false",
              message: "",
            })) || [];

          setAllAccounts((prevAccounts) => {
            if (newData.length > 0) {
              return [...prevAccounts, ...newData];
            } else {
              console.log("no data found");
              return prevAccounts;
            }
          });

          setAllBeneTxnAccountsLoading(false);

          const approved = newData.filter(
            (account) =>
              account.beneficiary_account_approval_status === "approved"
          );
          setApprovedAccounts((prevApproved) => [...prevApproved, ...approved]);
        })
        .catch(() => {
          setAllBeneTxnAccountsLoading(false);
        });
    } else if (label !== "Select category" && page !== 1) {
      // if (page !== 1) {
      //   setAllBeneTxnAccountsLoading(true);
      // }
      dispatch(
        internalSingleApproval({
          page_no: page,
          category: label !== "Select category" ? label : undefined,
          page_size: pageSize,
        })
      )
        .then((response) => {
          const newData =
            response?.payload?.transactions?.map((account) => ({
              ...account,
              loading: false,
              error: "false",
              message: "",
            })) || [];

          setAllAccounts((prevAccounts) => {
            if (newData.length > 0) {
              return [...prevAccounts, ...newData];
            } else {
              console.log("no data found");
              return prevAccounts;
            }
          });

          setAllBeneTxnAccountsLoading(false);

          const approved = newData.filter(
            (account) =>
              account.beneficiary_account_approval_status === "approved"
          );
          setApprovedAccounts((prevApproved) => [...prevApproved, ...approved]);
        })
        .catch(() => {
          setAllBeneTxnAccountsLoading(false);
        });
    }
  }, [page]);

  const handleSingleApprovalClick = () => {
    navigate("/internal_transaction/single_approval_transaction");
  };
  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1140 && screenWidth < 2560) {
      setPageSize(50);
    } else if (screenWidth >= 2560 && screenWidth < 3840) {
      setPageSize(102);
    } else if (screenWidth >= 3840) {
      setPageSize(250);
    }
  }

  const handleFilterCategory = () => {
    setCurrentCategory(label);
    setAllAccounts([]);

    if (page === 1) {
      setAllBeneTxnAccountsLoading(true);
    }
    dispatch(checkUsertoken());
    dispatch(
      internalSingleApproval({
        page_no: page,
        page_size: pageSize,
        category: label !== "Select category" ? label : undefined,
      })
    ).then((response) => {
      setAllAccounts(response?.payload?.transactions);

      setShowFilterErrorPage(true);
      setAllBeneTxnAccountsLoading(false);
      const approved = response?.payload?.transactions.filter(
        (account) => account.beneficiary_account_approval_status === "approved"
      );
      setApprovedAccounts(approved);
    });
    setInitCategory(false);
    setFilterOpen(false);
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const handleClearCategory = () => {
    setlabel("Select category");
    setCurrentCategory("");
    setInitCategory("");
    setAllBeneTxnAccountsLoading(true);
    setPage(1);
    if (label !== "Select category") {
      dispatch(checkUsertoken());
      dispatch(
        internalSingleApproval({
          page_no: page,
          page_size: pageSize,
          category: undefined,
        })
      ).then((response) => {
        setAllAccounts(response?.payload?.transactions);
        setAllBeneTxnAccountsLoading(false);
        setShowFilterErrorPage(false);
        const approved = response?.payload?.transactions.filter(
          (account) =>
            account.beneficiary_account_approval_status === "approved"
        );
        setApprovedAccounts(approved);
      });
    }
  };

  const handleSelectAll = (tt) => {
    const alreadySelectedCount = selectedTransactions?.length;
    const remainingSelections = 20 - alreadySelectedCount;
    if (tt) {
      let x = [];
      approvedAccounts.map((itm) => {
        if (selectedTransactions.includes(itm.txn_id)) {
          x.push(itm.txn_id);
        }
      });
      let y = selectedTransactions.filter((it) => !x.includes(it));
      let z = selectedSendTransactions.filter((itm) => !x.includes(itm.txn_id));
      setSelectedTransactions(y);
      setSelectedSendTransactions(z);
      let amt = z.reduce((sum, account) => sum + Number(account.amount), 0);
      setTotalAmount(amt);
    } else if (label === "Select category" && remainingSelections === 0) {
      setSelectedTransactions([]);
      setSelectedSendTransactions([]);
      setTotalAmount(0);
    } else {
      if (remainingSelections > 0) {
        const newSelections = allAccounts
          .filter(
            (account) =>
              account.beneficiary_account_approval_status === "approved" &&
              !selectedTransactions.includes(account.txn_id)
          )
          .slice(0, remainingSelections)
          .map((account) => account.txn_id);

        const newSelectionsSend = allAccounts
          .filter(
            (account) =>
              account.beneficiary_account_approval_status === "approved" &&
              !selectedTransactions.includes(account.txn_id)
          )
          .slice(0, remainingSelections)
          .map((account) => account);

        setSelectedTransactions((prevSelected) => [
          ...prevSelected,
          ...newSelections,
        ]);

        setSelectedSendTransactions((prevSelected) => [
          ...prevSelected,
          ...newSelectionsSend,
        ]);

        const newAmounts = allAccounts
          .filter((account) => newSelections.includes(account.txn_id))
          .reduce((sum, account) => sum + Number(account.amount), 0);

        setTotalAmount((prevAmount) => prevAmount + newAmounts);
      } else {
        let x = [];
        approvedAccounts.map((itm) => {
          if (selectedTransactions.includes(itm.txn_id)) {
            x.push(itm.txn_id);
          }
        });
        let y = selectedTransactions.filter((it) => !x.includes(it));
        let z = selectedSendTransactions.filter(
          (itm) => !x.includes(itm.txn_id)
        );
        setSelectedTransactions(y);
        setSelectedSendTransactions(z);
        let amt = z.reduce((sum, account) => sum + Number(account.amount), 0);
        setTotalAmount(amt);
      }
    }
  };

  useEffect(() => {
    const handlefilter = (e) => {
      if (filterRef.current && filterRef.current.contains(e.target)) {
        setFilterOpen(false);
      }
    };
    document.addEventListener("click", handlefilter);
  }, [filterRef]);

  return (
    <>
      {isMobileResponsive ? (
        filterOpen ? (
          <div>
            <BeneTxnFilter
              filteredType={"singleApproveType"}
              handleClearCategory={handleClearCategory}
              data={allAccounts}
              setFilterOpen={setFilterOpen}
              label={label}
              setlabel={setlabel}
              initCategory={initCategory}
              sendCategory={handleFilterCategory}
              filterOpen={filterOpen}
              setPage={setPage}
              setInitCategory={setInitCategory}
              transaction={true}
              setFilterCat={setShowFilterErrorPage}
            />
          </div>
        ) : (
          <div
            className=""
            style={{
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <div className="internalApprovalHeaderMob bg-[#3A5686]">
              <div
                className="txnBeneapprovalheaderarrowvcv-mob"
                onClick={() => navigate(-1)}
              >
                <img
                  src={LeftSign}
                  style={{
                    width: "16px",
                    height: "16px",
                    marginLeft: "10px",
                  }}
                  alt=""
                />
              </div>
              <div className="txnBeneapprovalheadertextmv-mob">
                <div className="headingAccountDetail">Internal</div>
                <div className="headertextbottomv_mob">
                  Transaction approval
                </div>
              </div>
              <div className="singleViewMob">
                <div className="multiImgMob">
                  {/* <div className="txnimgfv-mob">
                        <div className="mr-[-7px]">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title="Filters"
                          >
                            <div
                              className={`filter-main-mob`}
                              style={{
                                backgroundColor:
                                  currentCategory !== "" ? "#102C5D" : "",
                                height: currentCategory !== "" ? "32px" : "",
                                width: currentCategory !== "" ? "33px" : "",
                                borderRadius:
                                  currentCategory !== "" ? "5px" : "",
                                marginLeft: currentCategory !== "" ? "8px" : "",
                                opacity:
                                  allInternalTxnAcc?.length === 0 ? "0.5" : "1",
                              }}
                              onClick={() => {
                                if (
                                  ShowFilterErrorPage &&
                                  allInternalTxnAcc?.length === 0
                                ) {
                                  setFilterOpen(!filterOpen);
                                  setInitCategory(false);
                                } else if (allInternalTxnAcc?.length === 0) {
                                  setFilterOpen(filterOpen);
                                  setInitCategory(false);
                                } else if (allInternalTxnAcc?.length > 0) {
                                  setFilterOpen(!filterOpen);
                                  setInitCategory(false);
                                }
                              }}
                            >
                              <img
                                src={Filter}
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  marginTop:
                                    currentCategory !== "" ? "7px" : "",
                                }}
                                alt=""
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div> */}
                  <div className="txnimgmv-mob mr-[-7px]">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="List"
                    >
                      <img
                        className="w-[16px] h-[16px]"
                        onClick={() =>
                          navigate(
                            "/internal_transaction/single_approval_transaction"
                          )
                        }
                        src={ListWhite}
                        alt="ListWhite"
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex justify-between pl-[30px] pr-[30px] items-center h-[60px] bg-[#3A5686] bg-[0%_0%] bg-no-repeat opacity-100">
              <div
                className="flex items-center gap-5 cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img
                  className="w-[16px] h-[16px]"
                  src={arrow_left_white}
                  alt="No Images"
                />
                <div className="txnBeneapprovalheadertextmv-mob">
                  <div className="headingAccountDetail">Internal</div>
                  <div className="headertextbottomv_mob">
                    Transaction approval
                  </div>
                </div>
              </div>
              <div>
                <div className="flex justify-between items-center gap-3"> */}
            {/* <div
                    style={{
                      width: currentCategory ? "32px" : "",
                      height: currentCategory ? "32px" : "",
                      background: currentCategory ? "#102C5D" : "",
                      borderRadius: currentCategory ? "5px" : "",
                      display: currentCategory ? "flex" : "",
                      alignItems: currentCategory ? "center" : "",
                      justifyContent: currentCategory ? "center" : "",
                    }}
                  >
                    <img
                      onClick={() => {
                        if (ShowFilterErrorPage && allAccounts.length === 0) {
                          setFilterOpen(!filterOpen);
                          setInitCategory(false);
                        } else if (allAccounts.length === 0) {
                          setFilterOpen(filterOpen);
                          setInitCategory(false);
                        } else if (
                          allAccounts?.length === 0 ||
                          allAccounts?.length > 0
                        ) {
                          setFilterOpen(!filterOpen);
                          setInitCategory(false);
                        }
                      }}
                      className="w-[16px] h-[16px]"
                      src={Filter}
                      alt="Filter"
                    />
                  </div> */}

            {/* <div className="w-0 h-[28px] border-2 border-[#1E3A6D] opacity-100"></div> */}

            {/* <img
                    className="w-[16px] h-[16px]"
                    onClick={() =>
                      navigate(
                        "/internal_transaction/single_approval_transaction"
                      )
                    }
                    src={ListWhite}
                    alt="ListWhite"
                  />
                </div>
              </div>
            </div> */}
            <div className="h-[76px] bg-[#3A5686] bg-[0%_0%] bg-no-repeat opacity-100 mt-[1px] pl-[30px]">
              <div className="flex h-full justify-between items-center">
                <div className="flex flex-col">
                  <p className="h-[22px] text-left text-base font-semibold leading-[22px] text-[#4DDD37] whitespace-nowrap">
                    ₹ {<FormatAmount price={totalAmount} />}
                  </p>
                  <span className="text-left text-[10px] font-normal text-[#DADADA]">
                    Transaction amount
                  </span>
                </div>
                <div className="flex flex-col justify-center items-center w-[80px] h-[76px] bg-[#506994] bg-[0%_0%] bg-no-repeat opacity-100">
                  <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#667CA3] bg-[0%_0%] bg-no-repeat opacity-100 rounded-4xl">
                    <p className="w-[10px] flex justify-center items-center font-semibold text-base  font-sans tracking-normal text-white opacity-100">
                      {selectedTransactions.length}
                    </p>
                  </div>
                  <span className=" flex items-center mt-[7px] w-[40px]   text-[10px] font-normal leading-[14px] text-[#FFFFFF]">
                    Selected
                  </span>
                </div>
              </div>
            </div>

            {currentCategory && (
              <div className="  h-[59px] w-full bg-[#314D7E] flex items-center pt-[15px] pb-[15px] pl-[20px]">
                <div className="w-[80px] h-[32px]   rounded-[30px] border-[1px] bg-[#3A5686]">
                  {currentCategory !== "" && (
                    <div className=" w-full flex justify-between items-center h-[32px] pl-[6px] pr-[6px] pb-[2px]">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={currentCategory}
                      >
                        <p className=" w-[38px] text-[#F9F9F9] truncate font-normal text-xs">
                          {currentCategory}
                        </p>
                      </Tooltip>
                      <div
                        className="w-[14px] h-[14px]"
                        onClick={handleClearCategory}
                      >
                        <img
                          src={CloseBtn}
                          alt="close-btn"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div
              className="flex items-center justify-between pr-[25px] pl-[25px] h-[50px]"
              onClick={() => {
                setselectAllCard(
                  approvedAccounts.every((itm) =>
                    selectedTransactions.includes(itm.txn_id)
                  )
                    ? true
                    : selectAllCard
                    ? false
                    : true
                );
                handleSelectAll(
                  approvedAccounts.every((itm) =>
                    selectedTransactions.includes(itm.txn_id)
                  )
                );
              }}
            >
              {internalTxnSingleApprovalData !== undefined &&
                allAccounts?.length > 0 && (
                  <>
                    <div className="text-xs text-[#DADADA] font-normal leading-[22px]">
                      <p> Select all (Maximum 20)</p>
                    </div>
                    <div className="flex">
                      <IconButton
                        style={{
                          borderRadius: "10px",
                          height: "32px",
                          width: "32px",
                          marginLeft: "10px",
                          marginTop: "-3px",
                        }}
                      >
                        <img
                          className=""
                          src={
                            allAccounts?.length < 20 &&
                            approvedAccounts.length !== 0 &&
                            approvedAccounts.every((itm) =>
                              selectedTransactions.includes(itm.txn_id)
                            )
                              ? //   &&
                                // approvedAccounts.length === selectedTransactions.length
                                Selected
                              : selectedTransactions?.length === 20
                              ? Selected
                              : SelectWhite
                          }
                          alt="Select White"
                        />
                      </IconButton>
                    </div>
                  </>
                )}
            </div>
            <div
              className="w-full h-[45px] fixed bottom-0 cursor-pointer flex justify-center items-center z-40"
              style={{
                opacity: selectedTransactions?.length >= 1 ? 1 : 1,
                cursor:
                  selectedTransactions?.length >= 1 ? "pointer" : "default",
                backgroundColor:
                  selectedTransactions?.length >= 1 ? "#69B76F" : "#707070",
              }}
              onClick={() => {
                if (selectedTransactions?.length >= 1) {
                  const selectedData = {
                    selectedSendTransactions,
                    selectedTransactions,
                    timestamp: Date.now(),
                  };
                  sessionStorage.setItem(
                    "selectedData",
                    JSON.stringify(selectedData)
                  );

                  navigate("/internal_transaction/multi_approval_transaction");
                }
              }}
            >
              <p className="w-[78px] text-center font-semibold text-base leading-[14px] text-[#FFFFFF] opacity-100">
                Next
              </p>
            </div>

            <div
              className=""
              style={{
                height: currentCategory
                  ? "calc(100vh - 288px)"
                  : "calc(100vh - 228px)",
                overflowY: "hidden",
                paddingBottom: "20px",
              }}
            >
              <div id="txnViewallScrollContainer">
                {allBeneTxnAccountsLoading ? (
                  <div className="loader-container w-[80px] h-[80px] m-auto justify-center items-center flex mt-20">
                    <img src={Xpentraloader} alt="" />
                  </div>
                ) : internalTxnSingleApprovalData === undefined ||
                  allAccounts?.length === 0 ? (
                  <div
                    ref={filterRef}
                    className="BeneAccountMultiApproval_NoAccountFound"
                    style={{
                      opacity: filterOpen ? 0.2 : 1,
                    }}
                  >
                    <div className="w-[250px] h-[177px]">
                      <img
                        src={
                          ShowFilterErrorPage && allAccounts?.length === 0
                            ? FiterNoAccount
                            : NoAccountFound
                        }
                        alt=""
                      />
                    </div>
                    <div className="w-[282px] h-[44px] text-center text-[14px] font-medium leading-[22px] tracking-[0px] text-[#ffffff] opacity-[0.9]">
                      {ShowFilterErrorPage && allAccounts?.length === 0 ? (
                        <span className="">
                          No data found for the selected filter.
                        </span>
                      ) : (
                        <>
                          <span>
                            There are no more transactions that require your
                            approval.
                          </span>
                          <p className="w-[426px]"></p>
                        </>
                      )}
                    </div>
                  </div>
                ) : allAccounts?.length > 0 ? (
                  <div ref={filterRef}>
                    <div
                      className={`${
                        filterOpen
                          ? "disable_pointer"
                          : "flex flex-col items-center justify-center m-auto"
                      }`}
                    >
                      <InfiniteScroll
                        style={{ width: "inherit" }}
                        dataLength={allAccounts?.length || []}
                        next={fetchMoreData}
                        hasMore={
                          page <
                          Math.ceil(
                            internalTxnSingleApprovalData?.total_count /
                              pageSize
                          )
                        }
                        loader={
                          <div className="loader-container m-auto justify-center items-center flex">
                            <img src={Xpentraloader} alt="" />
                          </div>
                        }
                        scrollableTarget="txnViewallScrollContainer"
                        scrollThreshold={0.5}
                      >
                        <InternalTxnMultiApproval
                          filterOpen={filterOpen}
                          setAllAccounts={setAllAccounts}
                          data={allAccounts}
                          selectedTransactions={selectedTransactions}
                          setSelectedTransactions={setSelectedTransactions}
                          totalAmount={totalAmount}
                          setselectAllCard={setselectAllCard}
                          setTotalAmount={setTotalAmount}
                          selectedSendTransactions={selectedSendTransactions}
                          setSelectedSendTransactions={
                            setSelectedSendTransactions
                          }
                        />
                      </InfiniteScroll>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <div
          className={`${
            filterOpen ? "opacity-100" : "opacity-100"
          }shadow_MultiApproval_screen bg-custom-appBack flex flex-col h-screen`}
          id=" benetxnmain "
          style={{
            background: filterOpen ? "black" : "",
          }}
        >
          <div className="BenetxnMultiApprovalNavbar">
            <div className="internalMultiApprovalSec">
              <div className="internalTxnMultiApprovalSection">
                <div className="w-[1024px] mt-[105px] h-[90px] flex justify-between">
                  <div className="flex w-[236px]">
                    <div className="flex items-center flex-col w-[102px] h-[90px] bg-[#506994]">
                      <div className="bg-[#667CA3] text-[#F9F9F9] rounded-[50%] text-[14px] w-[34px] h-[35px] mt-5">
                        <p className="flex justify-center items-center text-xl pt-1">
                          {selectedTransactions.length}
                        </p>
                      </div>
                      <span className="flex items-center w-[40px] mt-1 text-[11px] font-normal leading-[14px] text-[#F9F9F9]">
                        Selected
                      </span>
                    </div>
                    <div className="min-w-0 flex w-auto justify-center flex-col gap-[4px] ml-[20px]">
                      <p className="inline-flex h-[22px] text-left text-base font-semibold leading-[22px] text-[#4DDD37] whitespace-nowrap">
                        ₹ {<FormatAmount price={totalAmount} />}
                      </p>
                      <span className="w-[114px] h-[17px] text-left text-xs font-normal leading-[17px] text-[#DADADA]">
                        Transaction amount
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between w-[250px]">
                    <div className=" flex w-[156px] justify-center items-center">
                      {/* <div className="Multifilter_div_container">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title="Filters"
                        >
                          <div
                            style={{
                              backgroundColor:
                                currentCategory !== "" ? "#F9F9F9" : "#1d3a6d ",
                              opacity:
                                ShowFilterErrorPage || allAccounts?.length >= 1
                                  ? "1"
                                  : "0.5",
                              cursor:
                                allAccounts?.length === 0 ? "auto" : "pointer",
                            }}
                            className="filter-main"
                            onClick={() => {
                              if (
                                ShowFilterErrorPage &&
                                allAccounts.length === 0
                              ) {
                                setFilterOpen(!filterOpen);
                                setInitCategory(false);
                              } else if (allAccounts.length === 0) {
                                setFilterOpen(filterOpen);
                                setInitCategory(false);
                              } else if (
                                allAccounts?.length === 0 ||
                                allAccounts?.length > 0
                              ) {
                                setFilterOpen(!filterOpen);
                                setInitCategory(false);
                              }
                            }}
                          >
                            <Box className="filters-box">
                              <img
                                src={
                                  currentCategory !== "" ? FilterBlue : Filter
                                }
                                alt=""
                                className="filter-images-element"
                              />
                            </Box>
                            <Box className="filter-box ">
                              <img
                                src={
                                  filterOpen
                                    ? currentCategory !== ""
                                      ? arrow_up_blue
                                      : ArrowUp
                                    : currentCategory !== ""
                                    ? arrow_down_blue
                                    : ArrowDown
                                }
                                alt=""
                                className="filter-images-element"
                              />
                            </Box>
                          </div>
                        </Tooltip>
                      </div> */}

                      <div className="MultiView_toggle">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title="Single Approval"
                        >
                          <Box
                            onClick={() => {
                              handleSingleApprovalClick();
                              setToggle(false);
                            }}
                            sx={{
                              backgroundColor: toggle ? "" : "#F9F9F9",
                            }}
                            className="toggle_box_InternalTxn"
                          >
                            <img
                              src={toggle ? ListWhite : ListBlue}
                              alt=""
                              className="toggle-images"
                            />
                          </Box>
                        </Tooltip>
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title="Multi Approval"
                        >
                          <Box
                            onClick={() => {
                              setToggle(true);
                            }}
                            sx={{
                              backgroundColor: toggle ? "#F9F9F9" : "",
                            }}
                            className="toggle_box_InternalTxn"
                          >
                            <img
                              src={toggle ? GridBlue : GridWhite}
                              alt=""
                              className="toggle-images"
                            />
                          </Box>
                        </Tooltip>
                      </div>
                    </div>
                    <div
                      className="flex flex-col items-center w-[102px] bg-[#506994]"
                      style={{
                        opacity: selectedTransactions?.length >= 1 ? 1 : 0.8,
                        cursor:
                          selectedTransactions?.length >= 1
                            ? "pointer"
                            : "default",
                      }}
                      onClick={() => {
                        if (selectedTransactions?.length >= 1) {
                          const selectedData = {
                            selectedSendTransactions,
                            selectedTransactions,
                            timestamp: Date.now(),
                          };
                          sessionStorage.setItem(
                            "selectedData",
                            JSON.stringify(selectedData)
                          );
                          navigate(
                            "/internal_transaction/multi_approval_transaction"
                          );
                        }
                      }}
                    >
                      <div
                        className={`flex flex-col items-center ${
                          selectedTransactions?.length >= 0
                            ? ""
                            : "hover:opacity-80 transition-opacity duration-200"
                        }`}
                      >
                        <div className="w-[34px] h-[35px] mt-5 flex justify-center items-center">
                          <img src={NextIcon} alt="Next Icon" />
                        </div>
                        <span className="mt-1 text-[11px] font-normal leading-[14px] text-[#F9F9F9] text-center">
                          Next
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {filterOpen && (
              <div
                className={`filteredClass-transition-${
                  filterOpen ? "open" : "close"
                } `}
              >
                <div className="filterdropdown-container">
                  <div className="filterdropdown">
                    <div
                      style={{
                        display: "flex",
                        width: "45%",
                      }}
                    >
                      <SelectCategoryTransaction
                        data={allAccounts}
                        label={label}
                        setlabel={setlabel}
                        initCategory={initCategory}
                        setFilterOpen={setFilterOpen}
                        sendCategory={handleFilterCategory}
                        filterOpen={filterOpen}
                        setPage={setPage}
                        setInitCategory={setInitCategory}
                        transaction={true}
                      />
                    </div>
                    <div className="button_container_div">
                      <div
                        style={{
                          cursor:
                            currentCategory !== "" ? "pointer" : "default",
                          hover: currentCategory !== "" ? "pointer" : "default",
                        }}
                        onClick={() => {
                          if (label !== "Select category") {
                            handleClearCategory();
                          }
                        }}
                        className="MultiApprovalclear"
                      >
                        <p className="buttoncontainer-text">Clear</p>
                      </div>
                      <div
                        onClick={() => {
                          if (label !== "Select category") {
                            handleFilterCategory();
                            setselectAllCard(false);
                          }
                        }}
                        className="multi-apply apply  hover:opacity-80"
                        style={{
                          backgroundColor:
                            label === "Select category" ? "#707070" : "#FB5963",

                          cursor:
                            label === "Select category" ? "default" : "pointer",
                          hover: currentCategory !== "" ? "pointer" : "default",
                        }}
                      >
                        <p className="buttoncontainer-text">Apply</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
          {/* {currentCategory && (
            <div className="bene_txn_MultiApproval_FilterBar">
              <div className="bene_txn_MultiApprovalfilterdropdown">
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div className="bene_txn_MultiApp_filter_div_left">
                    <p className="pr-2 w-[87px]">Filtered by : </p>
                    {currentCategory !== "" && (
                      <div className="bene_txn_MultiApp_filter_body px-1 pt-1">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={currentCategory}
                        >
                          <p className="px-2 text-xs truncate w-[100px] h-[19px]">
                            {currentCategory}
                          </p>
                        </Tooltip>
                        <div
                          className="bene_txn_MultiApp_close_btn"
                          onClick={handleClearCategory}
                        >
                          <img
                            src={closeBtnImg}
                            alt="close-btn"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )} */}

          <div
            style={{
              marginTop:
                currentCategory === ""
                  ? "0px"
                  : internalTxnSingleApprovalData !== undefined &&
                    allAccounts?.length > 0
                  ? "80px"
                  : "50px",
            }}
          >
            <div className="selectToProceed mx-auto">
              {internalTxnSingleApprovalData !== undefined &&
                allAccounts?.length > 0 && (
                  <>
                    <div className="text-base text-[#F9F9F9] font-semibold leading-[22px]">
                      <p>Select Transaction To Proceed</p>
                    </div>
                    <div
                      className="flex cursor-pointer hover:opacity-80 transition-opacity duration-200"
                      onClick={() => {
                        setselectAllCard(
                          approvedAccounts.every((itm) =>
                            selectedTransactions.includes(itm.txn_id)
                          )
                            ? true
                            : selectAllCard
                            ? false
                            : true
                        );
                        handleSelectAll(
                          approvedAccounts.every((itm) =>
                            selectedTransactions.includes(itm.txn_id)
                          )
                        );
                      }}
                    >
                      <p className="text-xs mt-1 text-[#DADADA] font-normal leading-[17px] cursor-pointer">
                        Select all (Maximum 20)
                      </p>
                      <IconButton
                        style={{
                          backgroundColor: "#314D7E",
                          borderRadius: "10px",
                          height: "30px",
                          width: "30px",
                          marginLeft: "10px",
                          marginTop: "-3px",
                        }}
                      >
                        <img
                          className=""
                          src={
                            allAccounts?.length < 20 &&
                            approvedAccounts.length !== 0 &&
                            approvedAccounts.every((itm) =>
                              selectedTransactions.includes(itm.txn_id)
                            )
                              ? //   &&
                                // approvedAccounts.length === selectedTransactions.length
                                Selected
                              : selectedTransactions?.length === 20
                              ? Selected
                              : SelectWhite
                          }
                          alt="Select White"
                        />
                      </IconButton>
                    </div>
                  </>
                )}
            </div>
          </div>

          <div
            id="txnViewallScrollContainer"
            style={{ overflow: filterOpen ? "hidden" : "auto" }}
          >
            {allBeneTxnAccountsLoading ? (
              <div className="loader-container m-auto justify-center items-center flex mt-20">
                <img src={Xpentraloader} alt="Loading..." />
              </div>
            ) : internalTxnSingleApprovalData === undefined ||
              allAccounts?.length === 0 ? (
              <div
                ref={filterRef}
                className="BeneAccountMultiApproval_NoAccountFound"
                style={{
                  opacity: filterOpen ? 0.2 : 1,
                }}
              >
                <div className="BeneAccount_RightTextContentNoAccount">
                  <img
                    src={
                      ShowFilterErrorPage && allAccounts?.length === 0
                        ? FiterNoAccount
                        : NoAccountFound
                    }
                    alt="No data found"
                  />
                </div>
                <div className="BeneAccount_LeftTextContentNoAccount">
                  {ShowFilterErrorPage && allAccounts?.length === 0 ? (
                    <span>No data found for the selected filter.</span>
                  ) : (
                    <>
                      {allAccounts?.length === 0 && (
                        <span>
                          There are no more transactions that require your
                          approval.
                        </span>
                      )}
                      <p className="w-[426px]"></p>
                    </>
                  )}
                </div>
              </div>
            ) : (
              allAccounts?.length > 0 && (
                <div ref={filterRef}>
                  <div
                    className={`${
                      filterOpen
                        ? "disable_pointer"
                        : "flex flex-col items-center justify-center m-auto"
                    }`}
                  >
                    <InfiniteScroll
                      style={{ width: "inherit" }}
                      dataLength={allAccounts?.length || 0}
                      next={fetchMoreData}
                      hasMore={
                        page <
                        Math.ceil(
                          internalTxnSingleApprovalData?.total_count / pageSize
                        )
                      }
                      loader={
                        <div className="loader-container m-auto justify-center items-center flex">
                          <img src={Xpentraloader} alt="Loading..." />
                        </div>
                      }
                      scrollableTarget="txnViewallScrollContainer"
                      scrollThreshold={0.5}
                    >
                      <InternalTxnMultiApproval
                        filterOpen={filterOpen}
                        setAllAccounts={setAllAccounts}
                        data={allAccounts}
                        selectedTransactions={selectedTransactions}
                        setSelectedTransactions={setSelectedTransactions}
                        totalAmount={totalAmount}
                        setselectAllCard={setselectAllCard}
                        setTotalAmount={setTotalAmount}
                        selectedSendTransactions={selectedSendTransactions}
                        setSelectedSendTransactions={
                          setSelectedSendTransactions
                        }
                      />
                    </InfiniteScroll>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default InternalTransactionMultiApproval;
