import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Box } from "@mui/material";
import formatDateViewAll from "../../../utils/formatDateViewAll";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import arrowforward from "../../../assets/Beneficiary Icons/arrowForward.png";
import salary from "../../../assets/Beneficiary Icons/salaries.png";
import { BorderAll } from "@mui/icons-material";
import FormatAmount from "../../_utils/FormatAmount/FormatAmount";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import zIndex from "@mui/material/styles/zIndex";
import confidential_transactionImg from "../../../assets/GeneralIcons/confidential_transaction.svg";

const InternalTxnViewAllCard = ({
  data,
  isSidebarOpen,
  isDistributionPopoverOpen,
  isPopoverOpen,
  currentStatus,
  txnHistorytype,
  onClose,
  isMobile,
}) => {
  const navigate = useNavigate();
  const benealldata = data;

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#B196FA";
      case "denied":
      case "failed":
        return "#ff5860";
      case "abandoned":
        return "#a3a3a3";
      case "rejected":
        return "#ff5860";
      case "hold":
        return "#FBC02D";
      case "queued":
        return "#FBC02D";
      default:
        return "#4ddd37";
    }
  };

  const getDistriStatusColor = (status) => {
    switch (status) {
      case "awaiting_verification":
      case "awaiting_funds":
      case "distribution_initiated":
      case "created":
      case "distribution_pending":
      case "approved":
        return "#FBC02D";
      case "denied":
      case "failed":
      case "rejected":
        return "#ff5860";
      case "abandoned":
        return "#B4B4B4";
      case "distribution_complete":
        return "#4DDD37";
      default:
        return "#F9F9F9";
    }
  };

  return (
    <div
      className={`internalTxnViewallCard gap-[20px] ${
        isSidebarOpen ? "sidebar-open" : ""
      }`}
      style={{
        paddingBottom:
          txnHistorytype === "ownerDetailTxnHistory"
            ? "200px"
            : txnHistorytype === "internalTxnHistory"
            ? "185px"
            : "165px",
        opacity:
          isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
            ? "0.4"
            : "1",
        background:
          isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
            ? "#000"
            : "",
      }}
    >
      {benealldata?.map((transactions, index) => (
        <div className="internalTxn_card-container" key={transactions.txn_id}>
          <Box
            key={index}
            className={`multiSelectInternalTxnViewAllCard relative hover:bg-[#506994] ${
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "disabled"
                : ""
            }`}
            onClick={() => {
              if (!isMobile) {
                if (txnHistorytype === "internalTxnHistory") {
                  onClose();
                  navigate(
                    `/transaction/internal_transaction/details/${transactions.txn_id}`
                  );
                } else if (
                  !isSidebarOpen &&
                  !isPopoverOpen &&
                  !isDistributionPopoverOpen
                ) {
                  navigate(
                    `/transaction/internal_transaction/details/${transactions.txn_id}`
                  );
                }
              } else {
                // toast.info("Coming Soon...", toast_position);
                navigate(
                  `/transaction/internal_transaction/details/${transactions.txn_id}`
                );
              }
            }}
          >
            <div
              className="internalTxn_viewall_details"
              style={{
                visibility: isSidebarOpen ? "visible" : "",
                animation: isSidebarOpen ? "none" : "",
              }}
            >
              <div className="internalTxn_view_all_user">
                <div className="internalTxn_viewall_name_div">
                  ₹ {<FormatAmount price={Number(transactions?.amount)} />}
                  <div className="internalTxn_viewall_acc_title">
                    {transactions.created_on
                      ? formatDateViewAll(transactions.created_on, false, true)
                      : "Not given"}
                  </div>
                </div>

                <div className="internalTxn_viewall_bank_acc_div">
                  <div className="internalTxn_account">
                    {" ••••  " +
                      transactions?.owner_account_bank_acc_no?.substring(
                        transactions?.owner_account_bank_acc_no?.length - 4
                      )}
                  </div>
                  <img
                    src={arrowforward}
                    className="internalTxn_viewall_arrow_icon"
                  />
                  <div className="internalTxn_account">
                    {" ••••  " +
                      transactions?.beneficiary_account_bank_acc_no?.substring(
                        transactions?.beneficiary_account_bank_acc_no?.length -
                          4
                      )}
                  </div>
                </div>
                <div className="internalTxn_viewall_inner_card_container flex flex-row justify-between items-center">
                  <div className="internalTxn_viewall_card_container mb-0 ">
                    <div className="internalTxn_logofirst flex justify-center group-hover:bg-blue-100 relative text-center bg-white">
                      <div className="internalTxn_viewall_payment_mode_div">
                        <img
                          src={require("../../../assets/IfscLogo/" +
                            IfscCodeMapper(
                              transactions.owner_account_ifsc_code
                            ))}
                          alt="bank"
                          className="internalTxn_viewall_paymentmode_image"
                        />
                      </div>
                    </div>
                  </div>
                  {transactions?.confidential_transaction === "Y" ? (
                    <>
                      <span>
                        <img
                          className="w-[20px] h-[20px]"
                          src={confidential_transactionImg}
                          alt="confidential"
                        />
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="internalTxn_viewall_card_container mb-0 ">
                    <div className="internalTxn_logo flex justify-center group-hover:bg-blue-100 relative text-center bg-white">
                      <div className="internalTxn_viewall_payment_mode_div">
                        <img
                          src={require("../../../assets/IfscLogo/" +
                            IfscCodeMapper(
                              transactions.beneficiary_account_ifsc_code
                            ))}
                          alt="bank"
                          className="internalTxn_viewall_paymentmode_image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="internalTxn_viewall_hover_content">
              <div className="pl-[10px] pt-[5px]">
                <div
                  className="text-sm pb-[2px] first-letter:capitalize font-semibold"
                  style={{
                    color:
                      transactions.lifecycle_status === "N/A"
                        ? "#F9F9F9"
                        : getStatusColor(transactions.lifecycle_status),
                  }}
                >
                  {currentStatus === "require approval"
                    ? "Require approval"
                    : transactions.lifecycle_status || "N/A"}
                </div>
                <div className="text-[#DADADA] text-[10px]">
                  Approval status
                </div>
              </div>

              <div className="pl-[10px] pt-[10px]">
                <div
                  className="text-sm pb-[2px]"
                  style={{
                    color:
                      transactions.distribution_lifecycle_status === "N/A"
                        ? "#F9F9F9"
                        : getDistriStatusColor(
                            transactions.distribution_lifecycle_status
                          ),
                  }}
                >
                  {
                    <>
                      {transactions.distribution_lifecycle_status === "created"
                        ? "Distribution created"
                        : transactions.distribution_lifecycle_status ===
                          "awaiting_funds"
                        ? "Awaiting funds"
                        : transactions.distribution_lifecycle_status ===
                          "distribution_initiated"
                        ? "Distribution initiated"
                        : transactions.distribution_lifecycle_status ===
                          "abandoned"
                        ? "Distribution abandoned"
                        : transactions.distribution_lifecycle_status ===
                          "rejected"
                        ? "Distribution rejected"
                        : transactions.distribution_lifecycle_status ===
                          "failed"
                        ? "Distribution failed"
                        : transactions.distribution_lifecycle_status ===
                          "distribution_complete"
                        ? "Distribution complete"
                        : transactions.distribution_lifecycle_status ===
                          "approved"
                        ? "Distribution approved"
                        : transactions.distribution_lifecycle_status || "N/A"}
                    </>
                  }
                </div>
                <div className="text-[#DADADA] text-[10px]">
                  Distribution status
                </div>
              </div>
            </div>
          </Box>
        </div>
      ))}
    </div>
  );
};

export default InternalTxnViewAllCard;
