import React, { useEffect, useState } from "react";
import "./RequireApproval.css";
import { useDispatch, useSelector } from "react-redux";
import xpentraloader from "../../../../assets/Beneficiary Icons/lodemore.gif";
import { toast } from "react-toastify";
import {
  achivedAccountPan,
  viewAccountDetail,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneDeniedAccount } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import {
  beneTxnDeniedApproval,
  beneTxnMultiDeniedApproval,
  beneTxnViewAllAcc,
  resetBeneTxnMultiDeniedApprovalLoading,
} from "../../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { internalTxnDenied } from "../../../../redux/features/internalTransaction/internalTransactionDetailPage";
import { useNavigate } from "react-router-dom";
import RefreshToast from "../../../../components/_utils/RefreshToast";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { resetBeneTxnMultiApprovalError } from "../../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import RejectingTransactionPopup from "./RejectingTransactionPopup/RejectingTransactionPopup";
import { achivedAccountDetail } from "../../../../redux/features/verifyPAN/verifyPAN";
import { internalTxnViewAll } from "../../../../redux/features/internalTransaction/internalTransactionViewAll";
import { internalTxnMultiRejectApproval } from "../../../../redux/features/internalTransaction/internalTransactionSingleApproval";

const DeclinedPopup = ({
  onClose,
  accountId,
  allAccounts,
  updateAllAccounts,
  type,
  status,
  sideDrawer,
  ShowAccountName,
  pageName,
  pathName,
  // showRejectTnxPopup,
  // setRejectTxnPopup,
  archive_id,
  setIsSidebarOpen,
  createArchive,
  createEdit,
  preventMultiEnter,
  setPreventMultiEnter,
  modaltype,
  closeBeneAccountDetailModal,
  txn_single_appr,
  modal_type,
  closeBeneTxnDetailModal,
  setTransactionFilterData,
  setTransactionFilterID,
  typeMulti,
  bene_txn_id,
  isMobile,
  closeInternalTxnDetailModal,
  internalAccount,
}) => {
  const [declineReason, setDeclineReason] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showTransitionClass, setShowTransitionClass] = useState("");
  const [cancleButtonHide, SetCancleButtonHide] = useState(true);
  const [isFocus, setIsFocus] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [showRejectTnxPopup, setRejectTxnPopup] = useState(false);

  const { viewAccountDetailData } = useSelector((state) => state.verify);
  const [confirmButtonStyle, setConfirmButtonStyle] = useState({
    width: "100px",
    height: "42px",
    background: "transparent",
    boxShadow: "0px 3px 6px #00000029",
    border: "2px solid #CCCCCC",
    borderRadius: "20px",
    opacity: "0.55",
    cursor: "initial",
    color: "#000000", // Default color
  });
  const {
    beneTxnAccountData,
    BeneTxnDeniedApprovalLoading,
    BeneTxnMultiDeniedApprovalLoading,
  } = useSelector((state) => state.beneTxnViewAccData);
  const { beneDeniedApprovalLoading } = useSelector(
    (state) => state.beneAllVerification
  );
  const { beneTxnViewAllAccLoading, beneTxnViewAllAccData } = useSelector(
    (state) => state?.beneTxnViewAccData
  );

  const { internalTxnData, internalTxnDeniedLoading } = useSelector(
    (state) => state?.internalTxnViewData
  );
  const services = useSelector((state) => state.refreshToken.services);

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const handleInputChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
    setDeclineReason(sanitizedValue);

    const isEmpty = value.trim() === "";

    const inputBorderColor =
      !isEmpty && value.replace(/\s+/g, "").length >= 10
        ? "1px solid #4DDD37"
        : "1px solid rgb(0, 255, 245)";

    if (!isEmpty && value.replace(/\s+/g, "").length >= 10) {
      setConfirmButtonStyle({
        width: "100px",
        height: "42px",
        background: "#3CB371",
        boxShadow: "0px 3px 6px #00000029",
        border: "none",
        borderRadius: "20px",
        opacity: "1",
        cursor: "pointer",
        color: "#FFFFFF",
      });
    } else {
      setConfirmButtonStyle({
        width: "100px",
        height: "42px",
        background: "transparent",
        boxShadow: "0px 3px 6px #00000029",
        border: "2px solid #CCCCCC",
        borderRadius: "20px",
        opacity: "0.55",
        cursor: "initial",
        color: "#000000",
      });
    }
  };
  const handleBlur = () => {
    if (declineReason?.length === 0) {
      setIsFocus(false);
    }
  };

  const handleTxnMultiRejecting = () => {
    if (
      BeneTxnMultiDeniedApprovalLoading ||
      BeneTxnMultiDeniedApprovalLoading == null ||
      BeneTxnDeniedApprovalLoading ||
      internalTxnDeniedLoading
    ) {
      RefreshToast();
    }
    setTimeout(() => {
      toast.dismiss();
    }, 2000);
  };

  const handleFocus = () => {
    setIsFocus(true);
  };

  const handleRejectTransactionChecker = async (id, AccountIdData) => {
    setShowLoader(true);
    setInputDisabled(true);
    SetCancleButtonHide(false);
    let queryParams = {
      page_no: 1,
      page_item_count: 50,
      lifecycle_status: "pending",
      beneficiary_account_id: id,
    };
    dispatch(checkUsertoken());
    let response = [];
    if (type === "internalAccount") {
      response = await dispatch(internalTxnViewAll(queryParams));
    } else {
      response = await dispatch(beneTxnViewAllAcc(queryParams));
    }

    const data = response?.payload?.transactions;
    if (data?.length > 0 && response?.payload?.status) {
      setRejectTxnPopup(true);
      setShowLoader(false);
      setPreventMultiEnter(true);
    } else {
      handleConfirm(id, AccountIdData);
    }
    setInputDisabled(false);
  };

  const handleConfirm = async (id, AccountIdData) => {
    setShowLoader(true);
    setInputDisabled(true);
    dispatch(checkUsertoken());
    RefreshToast("accountsRefresh");

    if (!beneDeniedApprovalLoading || beneDeniedApprovalLoading === null) {
      const data = await dispatch(beneDeniedAccount({ id, AccountIdData }));

      if (data?.payload?.errors?.user_unauthorized?.length > 0) {
        Navigate("/feature_not_assigned", {
          state: {
            unavailableService: "ABeneficiaryTxn",
          },
        });
        return;
      }

      setShowLoader(true);
      SetCancleButtonHide(false);
      await new Promise((resolve) => {
        setTimeout(resolve, 2000);
      });

      if (type === "decline account details") {
        if (data?.payload?.status === false || data?.payload === undefined) {
          let error_msg = "";
          let error_list = null;
          if (data?.payload === undefined) {
            error_msg = "An error occured while taking action on this account";
          } else if (data?.payload?.errors?.beneficiary_account) {
            if (data?.payload?.errors?.beneficiary_account?.length > 1) {
              error_list = data?.payload?.errors?.beneficiary_account;
              error_msg = data?.payload?.errors?.beneficiary_account[0];
            } else {
              error_msg = data?.payload?.errors?.beneficiary_account;
              toast.error(error_msg[0], toast_position);
            }
          } else if (data?.payload?.errors?.bank_account_number?.[0]) {
            error_msg = data?.payload?.errors?.bank_account_number[0];
          }

          if (error_msg === "Beneficiary account already rejected") {
            dispatch(checkUsertoken());
            const newData = await dispatch(viewAccountDetail({ id }));
            const updatedBeneficiaryAccount = {
              ...newData?.payload?.beneficiary_accounts?.[0],
              error_msg,
              error: data?.payload?.status === false ? true : false,
            };

            const updatedData = allAccounts.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  ...(updatedBeneficiaryAccount || {}),
                };
              }
              return item;
            });
            updateAllAccounts(updatedData);
          } else {
            const updatedData = allAccounts.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  error_msg,
                  error: data?.payload?.status === false ? true : false,
                };
              }
              return item;
            });
            updateAllAccounts(updatedData);
          }
          setShowLoader(false);
          setInputDisabled(false);
          setDeclineReason("");
          onClose();
          if (Array.isArray(error_list) && error_list.length > 1) {
            error_list.forEach((err) => {
              setTimeout(() => {
                toast.error(err, toast_position);
              }, 500);
            });
          } else {
            toast.error(error_msg, toast_position);
          }
        } else {
          const updatedData = allAccounts.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "rejected" : "pending",

                rejected_on:
                  data?.payload?.status === true
                    ? data?.payload?.beneficiary_accounts[0]?.rejected_on
                    : "",
                rejected_by_name:
                  data?.payload?.status === true
                    ? data?.payload?.beneficiary_accounts[0]?.rejected_by_name
                    : "",
                rejection_reason: AccountIdData,
                error:
                  item.error === false && data?.payload?.status === false
                    ? true
                    : false,
                message:
                  item.message === "" && data?.payload?.status === false
                    ? data?.payload?.errors?.beneficiary_account[0]
                    : "",
              };
            }
            return item;
          });

          updateAllAccounts(updatedData);
          setShowLoader(false);
          setInputDisabled(false);
          setDeclineReason("");
          onClose();

          toast.success("Account rejected successfully", toast_position);
          if (pathName) {
            setTimeout(() => {
              Navigate(pathName);
            }, 5000);
          }
          // }
          if (modaltype === "bene_account_detail_modal") {
            setTimeout(() => {
              closeBeneAccountDetailModal();
              window.location.reload();
            }, 5000);
          }
          if (pageName === "Success page decline account") {
            sessionStorage.setItem(
              "Create BeneAcc Approve and Decline status",
              true
            );
            Navigate(`/accounts/beneficiary_account/details/${id}`);
          }
          if (txn_single_appr === "txn_single_approval") {
            Navigate(-1);
          }
        }
      } else {
        if (data?.payload?.status === false) {
          let error_msg = "";
          if (data?.payload?.errors?.beneficiary_account?.[0]) {
            error_msg = data?.payload?.errors?.beneficiary_account[0];
          } else if (data?.payload?.errors?.bank_account_number?.[0]) {
            error_msg = data?.payload?.errors?.bank_account_number[0];
          } else {
            error_msg = "An unknown error occurred.";
          }
          const updatedData = allAccounts?.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "error" : "pending",

                rejected_on:
                  data.payload.status === true
                    ? data?.payload?.beneficiary_accounts[0]?.rejected_on
                    : "",
                rejected_by_name:
                  data.payload.status === true
                    ? data?.payload?.beneficiary_accounts[0]?.rejected_by_name
                    : "",
                rejection_reason: AccountIdData,
                error:
                  item.error === "false" && data?.payload?.status === false
                    ? "true"
                    : "false",
                message: data?.payload?.status === false ? error_msg : "",
              };
            }
            return item;
          });

          updateAllAccounts(updatedData);

          setShowLoader(false);
          setInputDisabled(false);
          setDeclineReason("");
          onClose();
          toast.error(error_msg, toast_position);
        } else {
          const updatedData = allAccounts.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "rejected" : "pending",

                rejected_on:
                  data.payload.status === true
                    ? data?.payload?.beneficiary_accounts[0]?.rejected_on
                    : "",
                rejected_by_name:
                  data.payload.status === true
                    ? data?.payload?.beneficiary_accounts[0]?.rejected_by_name
                    : "",
                rejection_reason: AccountIdData,
                error:
                  item.error === "false" && data?.payload?.status === false
                    ? "true"
                    : "false",
                message:
                  item.message === "" && data?.payload?.status === false
                    ? data?.payload?.errors?.beneficiary_account[0]
                    : "",
              };
            }
            return item;
          });

          updateAllAccounts(updatedData);
          setShowLoader(false);
          setInputDisabled(false);
          setDeclineReason("");
          onClose();
          if (data?.payload?.status === true) {
            toast.success("Account rejected successfully", toast_position);
          }
        }
      }
    }
  };

  const BeneTxnDeclinedPopUp = async (id, AccountIdData) => {
    if (!sessionStorage.getItem("q2p_token")) {
      dispatch(checkUsertoken());
      setIsSidebarOpen(false);
      return;
    }
    setShowLoader(true);

    setInputDisabled(true);
    SetCancleButtonHide(false);
    if (sideDrawer === "SideDrawer") {
      RefreshToast("accountsRefresh");
      dispatch(checkUsertoken());
      const data = await dispatch(beneDeniedAccount({ id, AccountIdData }));

      if (data?.payload?.errors?.user_unauthorized?.length > 0) {
        Navigate("/feature_not_assigned", {
          state: {
            unavailableService: "ABeneficiaryTxn",
          },
        });
        return;
      }
      let result5 = "";
      let result6 = "";
      let result7 = "";
      let totalData1 = "";
      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (data && data?.payload?.errors?.base?.[0] !== "Invalid token format") {
        const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
          (item) => {
            if (item.id === id) {
              let lifecycleStatus = "";

              if (data.payload.status === true) {
                lifecycleStatus = "rejected";
              }
              if (data.payload.status === false) {
                lifecycleStatus = "error";
              }

              return {
                ...item,
                approval_status:
                  item.approval_status === "pending"
                    ? lifecycleStatus
                    : "pending",
                status: data?.payload?.status,
                message:
                  data?.payload?.status === false
                    ? data?.payload?.errors?.beneficiary_account?.[0]
                    : "",
              };
            }
            return item;
          }
        );

        if (data?.payload.status === false) {
          if (data?.payload.status === false) {
            if (
              data?.payload?.errors?.bank_account_number?.[0] &&
              data?.payload?.errors?.permanent_account_number?.[0] &&
              data?.payload?.errors?.beneficiary_account?.[0]
            ) {
              result5 = data?.payload?.errors?.bank_account_number[0];
              result6 = data?.payload?.errors.permanent_account_number[0];
              result7 = data.payload.errors.beneficiary_account[0];
              totalData1 = [result5, result6, result7];

              totalData1.forEach((error5) => {
                if (data?.payload?.status === false) {
                  setTimeout(() => {
                    toast.error(error5, toast_position);
                  }, 800);
                }
              });
            } else if (
              !data?.payload?.errors?.user_unauthorized?.[0] &&
              data?.payload?.errors?.beneficiary_account.length >= 0
            ) {
              const res = data.payload.errors.beneficiary_account;
              res.forEach((err8) => {
                if (data?.payload?.status === false) {
                  setTimeout(() => {
                    toast.error(err8, toast_position);
                  }, 1000);
                }
              });
            } else if (data?.payload?.errors?.user_unauthorized?.[0]) {
              if (data?.payload?.status === false) {
                setTimeout(() => {
                  toast.error(
                    data?.payload?.errors?.user_unauthorized?.[0],
                    toast_position
                  );
                }, 1000);
              }
            }
          }
        } else {
          toast.success("Transaction rejected successfully", toast_position);
        }
        updateAllAccounts(updatedData[0]);
      }
    } else if (sideDrawer === "multiApproval") {
      dispatch(checkUsertoken());
      handleTxnMultiRejecting();
      const data = await dispatch(
        beneTxnMultiDeniedApproval({ id, AccountIdData })
      );
      dispatch(resetBeneTxnMultiApprovalError());
      dispatch(resetBeneTxnMultiDeniedApprovalLoading());
      await new Promise((resolve) => setTimeout(resolve, 2000));
      SetCancleButtonHide(false);

      if (data?.payload?.status === 500) {
        setTimeout(() => {
          toast.error(data?.payload?.error, toast_position);
        }, 1000);
      } else if (data?.payload?.errors?.user_unauthorized?.[0]) {
        setTimeout(() => {
          Navigate("/feature_not_assigned", {
            state: {
              unavailableService: "VBeneficiaryTxn",
            },
          });
        }, 1000);
      } else if (data?.payload?.status === false) {
        // const error_msg = data?.payload?.errors?.base[0]
        //   ? data?.payload?.errors?.base[0]
        //   : data?.payload?.errors?.base[0].errors[0];
        // toast.error(error_msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: false,
        //   progress: undefined,
        //   theme: "colored",
        // });

        Navigate(
          "/transaction/Beneficiary_Transaction/multi_transaction_approval/error"
        );
      } else {
        Navigate(
          "/transaction/Beneficiary_Transaction/multi_transaction_approval/declined"
        );
      }
    } else {
      dispatch(checkUsertoken());
      handleTxnMultiRejecting();

      const data = await dispatch(beneTxnDeniedApproval({ id, AccountIdData }));

      await new Promise((resolve) =>
        setTimeout(() => {
          resolve();
        }, 2000)
      );
      if (data?.payload?.status === 500) {
        setTimeout(() => {
          toast.error(data?.payload?.error, toast_position);
        }, 1000);
      } else if (data?.payload?.errors?.user_unauthorized?.[0]) {
        setTimeout(() => {
          Navigate("/feature_not_assigned", {
            state: {
              unavailableService: "VBeneficiaryTxn",
            },
          });
        }, 1000);
      } else {
        SetCancleButtonHide(false);
        const updatedData = allAccounts.map((item) => {
          if (item?.txn_id === id) {
            let lifecycleStatus = "";
            let errorMessage = "";

            if (
              data.payload.status === true &&
              data.payload.transactions[0].lifecycle_status === "rejected"
            ) {
              lifecycleStatus = data.payload.transactions[0].lifecycle_status;
            }

            if (
              data?.payload?.errors?.base[0]?.errors &&
              data?.payload?.errors?.base[0]?.errors[0]
            ) {
              errorMessage = data.payload.errors.base[0].errors[0];
              if (data?.payload?.errors?.base[0]?.errors?.length >= 1) {
                let msg3 = data.payload.errors.base[0].errors;
                msg3.forEach((error2) => {
                  if (data?.payload?.status === false) {
                    setTimeout(() => {
                      toast.error(error2, toast_position);
                    }, 1000);
                  }
                });
              }
            } else if (data?.payload?.errors?.base[0]) {
              errorMessage = data.payload.errors.base[0];
            } else if (
              data?.payload?.status === true &&
              data?.payload?.transactions[0].lifecycle_status === "pending"
            ) {
              lifecycleStatus = "pendingOne";
            }

            return {
              ...item,
              lifecycle_status:
                data?.payload?.status === true &&
                item.lifecycle_status === "pending"
                  ? lifecycleStatus
                  : "error",
              rejected: true,
              rejection_reason: AccountIdData,
              error:
                item.error === "false" && data?.payload?.status === false
                  ? "true"
                  : "false",
              message:
                item.message === "" && data?.payload?.status === false
                  ? errorMessage
                  : "",
            };
          }
          return item;
        });

        updateAllAccounts(updatedData);

        if (data?.payload.status === false) {
          const error_msg = data?.payload?.errors?.base[0]
            ? data?.payload?.errors?.base[0]
            : data?.payload?.errors?.base[0].errors[0];
          toast.error(error_msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.success("Transaction rejected  successfully", toast_position);
        }
      }
    }
    setShowTransitionClass("transition-effect");

    setShowLoader(false);
    setInputDisabled(false);
    setDeclineReason("");
    onClose();
  };

  const BeneTxnDeclinedDtls = async (id, AccountIdData) => {
    setShowLoader(true);
    setInputDisabled(true);
    handleTxnMultiRejecting();
    if (sideDrawer === "SideDrawer") {
      RefreshToast("accountsRefresh");
      dispatch(checkUsertoken());
      const data = await dispatch(beneDeniedAccount({ id, AccountIdData }));

      if (data?.payload?.errors?.user_unauthorized?.length > 0) {
        Navigate("/feature_not_assigned", {
          state: {
            unavailableService: "ABeneficiaryTxn",
          },
        });
        return;
      }

      if (data) {
        const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
          (item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "rejected" : "approved",
                status: data?.payload?.status,
                message:
                  data?.payload?.status === false
                    ? data?.payload?.errors?.beneficiary_account[0]
                    : "",
              };
            }
            return item;
          }
        );
        if (data?.payload.status === false) {
          toast.error(data?.payload?.errors?.beneficiary_account[0], {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.success("Transaction rejected successfully", toast_position);
        }
        updateAllAccounts(updatedData[0]);
      }
    } else {
      dispatch(checkUsertoken());
      let data = {};
      if (status === "internal-transaction") {
        data = await dispatch(internalTxnDenied({ id, AccountIdData }));
      } else {
        data = await dispatch(beneTxnDeniedApproval({ id, AccountIdData }));
      }

      await new Promise((resolve) => setTimeout(resolve, 2000));

      if (data?.payload?.errors?.user_unauthorized?.[0]) {
        setTimeout(() => {
          Navigate("/feature_not_assigned", {
            state: {
              unavailableService: "VBeneficiaryTxn",
            },
          });
        }, 1000);
      } else {
        SetCancleButtonHide(false);
        const updatedData = allAccounts.map((item) => {
          if (item?.txn_id === id) {
            let lifecycleStatus = "";
            let errorMessage = "";

            if (
              data.payload.status === true &&
              data.payload.transactions[0].lifecycle_status === "rejected"
            ) {
              lifecycleStatus = data.payload.transactions[0].lifecycle_status;
            } else if (
              data?.payload?.errors?.base[0]?.errors &&
              data?.payload?.errors?.base[0]?.errors[0]
            ) {
              errorMessage = data.payload.errors.base[0].errors[0];
              if (data?.payload?.errors?.base[0]?.errors.length >= 1) {
                let msg1 = data?.payload?.errors?.base[0]?.errors;
                msg1.forEach((error1) => {
                  if (data?.payload?.status === false) {
                    setTimeout(() => {
                      toast.error(error1, toast_position);
                    }, 1000);
                  }
                });
              }
            } else if (data?.payload?.errors?.base[0]) {
              errorMessage = data.payload.errors.base[0];
              toast.error(errorMessage, toast_position);
            } else if (
              data.payload.status === true &&
              data.payload.transactions[0].lifecycle_status === "pending"
            ) {
              lifecycleStatus = "pendingOne";
            }

            return {
              ...item,
              lifecycle_status:
                data?.payload.status === true &&
                item.lifecycle_status === "pending"
                  ? lifecycleStatus
                  : "pending",
              rejected: true,
              rejection_reason: AccountIdData,
              logs: data?.payload?.transactions?.[0]?.logs
                ? data?.payload?.transactions[0]?.logs
                : status === "internal-transaction"
                ? internalTxnData?.transaction?.[0]?.logs || []
                : beneTxnAccountData?.transaction?.[0]?.logs || [],
              error:
                item.error === "false" && data?.payload?.status === false
                  ? "true"
                  : "false",
              message:
                item.message === "" && data?.payload?.status === false
                  ? errorMessage
                  : "",
            };
          }
          return item;
        });

        updateAllAccounts(updatedData);
        if (data?.payload?.status === false) {
          const error_msg = data?.payload?.errors?.base[0]
            ? data?.payload?.errors?.base[0]
            : data?.payload?.errors?.base[0].errors[0];
        } else {
          if (typeMulti === "MultiApproval_Session_Data") {
            let storedData = JSON.parse(sessionStorage.getItem("selectedData"));

            if (storedData) {
              const updatedTransactions =
                storedData.selectedSendTransactions.filter(
                  (transaction) => transaction.txn_id !== bene_txn_id
                );
              const updatedTransactionsId =
                storedData.selectedTransactions.filter(
                  (transaction) => transaction !== bene_txn_id
                );

              sessionStorage.setItem(
                "selectedData",
                JSON.stringify({
                  ...storedData,
                  selectedSendTransactions: updatedTransactions,
                  selectedTransactions: updatedTransactionsId,
                })
              );
              setTransactionFilterData(updatedTransactions);
              setTransactionFilterID(updatedTransactionsId);
            }

            toast.success("Transaction rejected successfully", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "colored",
            });
            if (modal_type === "bene_txn_detail_modal") {
              setTimeout(() => {
                closeBeneTxnDetailModal();
                window.location.reload();
              }, 5000);
            }
          } else {
            toast.success("Transaction rejected successfully", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "colored",
            });
          }

          if (
            modal_type === "bene_txn_detail_modal" ||
            modal_type === "internal_txn_detail_modal"
          ) {
            setTimeout(() => {
              modal_type === "bene_txn_detail_modal"
                ? closeBeneTxnDetailModal()
                : closeInternalTxnDetailModal();
              window.location.reload();
            }, 5000);
          }
        }
      }
    }
    setShowTransitionClass("transition-effect");
    setShowLoader(false);
    setInputDisabled(false);
    setDeclineReason("");
    onClose();
  };

  const { internalTxnViewAllAccData, internalTxnViewAllAccLoading } =
    useSelector((state) => state?.internalTxnViewall);

  const handleRejectAllTransaction = async () => {
    setShowLoader(true);
    setInputDisabled(true);
    let AccountIdData = declineReason;

    let all_txn_id = [];
    if (
      window.location.pathname.includes("/accounts/internal_account/details/")
    ) {
      all_txn_id =
        !internalTxnViewAllAccLoading &&
        internalTxnViewAllAccData?.transactions?.length > 0
          ? internalTxnViewAllAccData?.transactions.map((data) => {
              return data?.txn_id;
            })
          : [];
    } else {
      all_txn_id =
        !beneTxnViewAllAccLoading &&
        beneTxnViewAllAccData?.transactions?.length > 0
          ? beneTxnViewAllAccData?.transactions.map((data) => {
              return data?.txn_id;
            })
          : [];
    }

    dispatch(checkUsertoken());
    RefreshToast();
    let data = [];
    if (
      window.location.pathname.includes("/accounts/internal_account/details/")
    ) {
      data = await dispatch(
        internalTxnMultiRejectApproval({ id: all_txn_id, AccountIdData })
      );
    } else {
      data = await dispatch(
        beneTxnMultiDeniedApproval({ id: all_txn_id, AccountIdData })
      );
    }

    if (data?.payload?.errors?.user_unauthorized?.length > 0) {
      Navigate("/feature_not_assigned", {
        state: {
          unavailableService: "ABeneficiaryTxn",
        },
      });
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 2000));

    if (data?.payload?.status === false) {
      const error_msg = data?.payload?.errors?.user_unauthorized
        ? data?.payload?.errors?.user_unauthorized[0]
        : data?.payload?.errors?.base[0].errors[0]
        ? data?.payload?.errors?.base[0].errors[0]
        : data?.payload?.errors?.base[0];
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        onClose();
      }, 500);
    } else if (data?.payload?.status) {
      toast.success("Transaction Rejected successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });

      let thunkAction = achivedAccountDetail;
      dispatch(checkUsertoken());
      RefreshToast("archive");
      let archived_response;
      if (createArchive == "createArchive") {
        archived_response = await dispatch(
          achivedAccountPan({ id: archive_id })
        );
      } else {
        archived_response = await dispatch(thunkAction({ id: archive_id }));
        toast.success("Account archived successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      if (
        (archived_response.type !== "achivedAccountDetail/rejected" ||
          archived_response.payload ===
            "Beneficiary account already archived") &&
        !createEdit
      ) {
        dispatch(checkUsertoken());
        await dispatch(viewAccountDetail({ id: archive_id }));
      }
      if (window.location.pathname !== "/accounts/beneficiary_account/create") {
        toast.success("Account archived successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setTimeout(() => {
        onClose();
      }, 500);
    } else if (data?.payload == undefined) {
      toast.error("An error occured while taking action on this account", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        setRejectTxnPopup(false);
      }, 1000);
    }

    setInputDisabled(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const emojiRegex =
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
      if (emojiRegex.test(event.key)) {
        event.preventDefault();
        return;
      }

      if (event.key === "Enter" && !inputDisabled) {
        if (declineReason.replace(/\s+/g, "").length >= 10) {
          if (
            status === "Transaction" ||
            status === "MultiApprovalDeclinedTransaction"
          ) {
            BeneTxnDeclinedPopUp(accountId, declineReason);
          } else if (
            status === "Transaction-bene" ||
            status === "internal-transaction"
          ) {
            BeneTxnDeclinedDtls(accountId, declineReason);
          } else if (status === "Reject-All-Transaction") {
            if (!showLoader) {
              handleRejectAllTransaction();
            }
          } else if (
            !beneDeniedApprovalLoading ||
            beneDeniedApprovalLoading === null
          ) {
            if (!preventMultiEnter) {
              handleRejectTransactionChecker(accountId, declineReason);
            }
          }
        }
      }

      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    inputDisabled,
    declineReason,
    accountId,
    status,
    preventMultiEnter,
    showLoader,
  ]);

  return (
    <>
      {showRejectTnxPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center  z-[2147483647]">
          <RejectingTransactionPopup
            accountId={accountId}
            declineReason={declineReason}
            handleClosePopup={onClose}
            handleConfirm={handleConfirm}
            setRejectTxnPopup={setRejectTxnPopup}
            showRejectTnxPopup={showRejectTnxPopup}
            type={type}
          />
        </div>
      )}
      <div className="mainPopup">
        {status === "Transaction" ? (
          <div className="DeclinedAndpopup">
            <div
              className="declinedReasion"
              style={{
                marginTop: isMobile ? "-5px" : "",
              }}
            >
              <h2>Rejected reason</h2>
              {ShowAccountName ? (
                <p>Please state the reason for rejecting the account</p>
              ) : (
                <p>Please state the reason for rejecting the transaction</p>
              )}
            </div>

            <div
              className={`Beneinputfield ${
                declineReason.length >= 9 ? "valid" : ""
              }`}
              style={{
                marginTop:
                  isFocus && window.innerWidth < 600
                    ? "15px"
                    : window.innerWidth < 600
                    ? "15px"
                    : "15px",
              }}
            >
              <input
                style={{
                  border:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "1px solid #00FF14"
                      : isFocus
                      ? "1px solid #00FFF5"
                      : "1px solid #dadada",
                  pointerEvents: inputDisabled ? "none" : "auto",
                  disabled: inputDisabled ? "true" : "false",
                  width: "95%",
                  maxWidth: "501px",
                  height: "42px",
                  font: "16px",
                  padding: "0px 10px",
                  background: "transparent",
                  borderRadius: "10px",
                  outline: "none",
                  opacity: "1",
                }}
                type="text"
                inputMode="text"
                pattern="[A-Za-z0-9\s]*"
                value={declineReason}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
                disabled={inputDisabled}
              />
              <label
                htmlFor=""
                for="myInput"
                style={{
                  top: isFocus ? "-0px" : "22px",
                  fontSize: isFocus ? "12px" : "16px",
                  background: "#153164",
                  color:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "#00FF14"
                      : isFocus
                      ? "#00FFF5"
                      : "#dadada",
                }}
              >
                Reason*
              </label>
            </div>
            <div
            // style={{ paddingLeft: window.innerWidth < 600 ? "14px" : "32px" }}
            >
              <p
                className="w-full h-[19px] font-sans text-[#DADADA] text-[14px] leading-[19px] flex-none order-1"
                style={{
                  marginTop: isMobile ? "15px" : "",
                  marginLeft: isMobile ? "4.5%" : "5.5%",
                }}
              >
                Note - The reason must consist of at least 10 characters.
              </p>
            </div>
            <div
              className="BeneTxn_benefbtnconfandCan"
              style={{ marginTop: isMobile ? "20px" : "" }}
            >
              {showLoader ? (
                <button id="loader" className="BeneTxn_expentraLoaderclass">
                  <img src={xpentraloader} alt="Loading..." />
                </button>
              ) : (
                <div
                  className="flex items-center gap-[20px]"
                  style={{ marginBottom: isMobile ? "10px" : "" }}
                >
                  {cancleButtonHide && (
                    <button
                      id="cancelBtn"
                      onClick={onClose}
                      className={`cancleBtn ${showTransitionClass}`}
                    >
                      Cancel
                    </button>
                  )}

                  <button
                    // className={`confirmBtn ${showTransitionClass}`}
                    className={`${
                      declineReason.replace(/\s+/g, "").length < 10
                        ? "confirmBtn"
                        : "confirm-btn-enable"
                    }`}
                    // onClick={() =>
                    //   status ===
                    //   ("Transaction" || "MultiApprovalDeclinedTransaction")
                    //     ? BeneTxnDeclinedPopUp(accountId, declineReason)
                    //     : handleConfirm(accountId, declineReason)
                    // }
                    onClick={() => {
                      if (
                        services?.find(
                          (service) =>
                            service === "ABeneficiaryTxn" || service === "Admin"
                        ) !== undefined
                      ) {
                        status ===
                        ("Transaction" || "MultiApprovalDeclinedTransaction")
                          ? BeneTxnDeclinedPopUp(accountId, declineReason)
                          : handleConfirm(accountId, declineReason);
                      } else {
                        Navigate("/feature_not_assigned", {
                          state: {
                            unavailableService: "ABeneficiaryTxn",
                          },
                        });
                      }
                    }}
                    disabled={
                      inputDisabled ||
                      declineReason.replace(/\s+/g, "").length < 10
                    } // pp
                    style={{
                      cursor:
                        inputDisabled ||
                        declineReason.replace(/\s+/g, "").length <= 10
                          ? "default"
                          : "pointer",
                    }}
                  >
                    Reject
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : status === "Transaction-bene" ||
          status === "internal-transaction" ? (
          <div
            className="DeclinedAndpopup"
            onClick={(e) => e.stopPropagation()}
          >
            <div>
              <div className="declinedReasion">
                <h2>Rejected reason</h2>
                <p>Please state the reason for rejecting the transaction</p>
              </div>

              <div
                className={`Beneinputfield ${
                  declineReason.length >= 9 ? "valid" : ""
                }`}
                style={{ marginTop: "20px" }}
              >
                <input
                  style={{
                    border:
                      declineReason.replace(/\s+/g, "").length >= 10
                        ? "1px solid #00FF14"
                        : isFocus
                        ? "1px solid #00FFF5"
                        : "1px solid #dadada",
                    pointerEvents: inputDisabled ? "none" : "auto",
                    disabled: inputDisabled ? "true" : "false",
                    width: "501px",
                    height: "42px",
                    font: "16px",
                    padding: "0px 10px",
                    background: "transparent",
                    borderRadius: "10px",
                    outline: "none",
                    opacity: "1",
                  }}
                  type="text"
                  value={declineReason}
                  onChange={handleInputChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                  disabled={inputDisabled}
                />
                <label
                  htmlFor=""
                  for="myInput"
                  style={{
                    top: isFocus ? "-0px" : "22px",
                    fontSize: isFocus ? "12px" : "16px",
                    background: "#153164",
                    color:
                      declineReason.replace(/\s+/g, "").length >= 10
                        ? "#00FF14"
                        : isFocus
                        ? "#00FFF5"
                        : "#dadada",
                  }}
                >
                  Reason*
                </label>
              </div>
              <div className="txn_note mt-[18px] mb-[18px]">
                <p className="font-sans text-[#DADADA] text-[14px] leading-[19px] flex-none order-1">
                  Note - The reason must consist of at least 10 characters.
                </p>
              </div>
              <div className="BeneTxn_benefbtnconfandCan">
                {showLoader ? (
                  <button id="loader" className="BeneTxn_expentraLoaderclass">
                    <img src={xpentraloader} alt="Loading..." />
                  </button>
                ) : (
                  <div className="flex items-center gap-[30px] mobDeclineCancelConfirmS">
                    {cancleButtonHide && (
                      <button
                        id="cancelBtn"
                        onClick={onClose}
                        className={`cancleBtn ${showTransitionClass}`}
                      >
                        Cancel
                      </button>
                    )}

                    <button
                      className={`${
                        declineReason.replace(/\s+/g, "").length < 10
                          ? "confirmBtn"
                          : "confirm-btn-enable"
                      }`}
                      // onClick={() =>
                      //   status === "Transaction-bene"
                      //     ? BeneTxnDeclinedDtls(accountId, declineReason)
                      //     : handleConfirm(accountId, declineReason)
                      // }
                      onClick={() => {
                        if (
                          services?.find(
                            (service) =>
                              service === "ABeneficiaryTxn" ||
                              service === "Admin"
                          ) !== undefined
                        ) {
                          status === "Transaction-bene" ||
                          status === "internal-transaction"
                            ? BeneTxnDeclinedDtls(accountId, declineReason)
                            : handleConfirm(accountId, declineReason);
                        } else {
                          Navigate("/feature_not_assigned", {
                            state: {
                              unavailableService: "ABeneficiaryTxn",
                            },
                          });
                        }
                      }}
                      disabled={
                        inputDisabled ||
                        declineReason.replace(/\s+/g, "").length < 10
                      } // pp
                      style={{
                        cursor:
                          inputDisabled ||
                          declineReason.replace(/\s+/g, "").length <= 10
                            ? "default"
                            : "pointer",
                      }}
                    >
                      Reject
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : status === "Reject-All-Transaction" ? (
          <div
            className="DeclinedAndpopup"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="declinedReasion">
              <h2>Rejected reason</h2>
              <p>Please state the reason for rejecting the transaction</p>
            </div>

            <div
              className={`Beneinputfield ${
                declineReason.replace(/\s+/g, "").length >= 9 ? "valid" : ""
              }`}
            >
              <input
                style={{
                  border:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "1px solid #00FF14"
                      : isFocus
                      ? "1px solid #00FFF5"
                      : "1px solid #dadada",
                  pointerEvents: inputDisabled ? "none" : "auto",
                  disabled: inputDisabled ? "true" : "false",
                  width: "501px",
                  height: "42px",
                  font: "16px",
                  padding: "0px 10px",
                  background: "transparent",
                  borderRadius: "10px",
                  outline: "none",
                  opacity: "1",
                }}
                type="text"
                value={declineReason}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
                disabled={inputDisabled}
              />
              <label
                htmlFor=""
                for="myInput"
                style={{
                  top: isFocus ? "-0px" : "22px",
                  fontSize: isFocus ? "12px" : "16px",
                  background: "#153164",
                  color:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "#00FF14"
                      : isFocus
                      ? "#00FFF5"
                      : "#dadada",
                }}
              >
                Reason*
              </label>
            </div>

            <div className="BeneTxn_benefbtnconfandCan">
              {showLoader ? (
                <button id="loader" className="BeneTxn_expentraLoaderclass">
                  <img src={xpentraloader} alt="Loading..." />
                </button>
              ) : (
                <div className="flex items-center gap-[20px]">
                  {cancleButtonHide && (
                    <button
                      id="cancelBtn"
                      onClick={onClose}
                      className={`cancleBtn ${showTransitionClass}`}
                    >
                      Cancel
                    </button>
                  )}

                  <button
                    className={`${
                      declineReason.replace(/\s+/g, "").length < 10
                        ? "confirmBtn"
                        : "confirm-btn-enable"
                    }`}
                    onClick={() => {
                      if (
                        services?.find(
                          (service) =>
                            service === "ABeneficiaryTxn" || service === "Admin"
                        ) !== undefined
                      ) {
                        handleRejectAllTransaction();
                      } else {
                        Navigate("/feature_not_assigned", {
                          state: {
                            unavailableService: "ABeneficiaryTxn",
                          },
                        });
                      }
                    }}
                    disabled={
                      inputDisabled ||
                      declineReason.replace(/\s+/g, "").length < 10
                    }
                    style={{
                      cursor:
                        inputDisabled ||
                        declineReason.replace(/\s+/g, "").length < 10
                          ? "default"
                          : "pointer",
                    }}
                  >
                    Reject
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="DeclinedAndpopup">
            <div className="declinedReasion">
              <h2>Rejected reason</h2>
              <p style={{ color: "var(--Primary_white) !important" }}>
                Please state the reason for rejecting the account
              </p>
            </div>
            <div className="Beneinputfield">
              <input
                style={{
                  border:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "1px solid #00FF14"
                      : isFocus
                      ? "4DDD37"
                      : "1px solid #dadada",
                  pointerEvents: inputDisabled ? "none" : "auto",
                  disabled: inputDisabled ? "true" : "false",
                  width: "95%",
                  maxWidth: "501px",
                  height: "42px",
                  font: "16px",
                  padding: "0px 10px",
                  background: "transparent",
                  borderRadius: "10px",
                  outline: "none",
                  opacity: "1",
                }}
                type="text"
                value={declineReason}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleInputChange}
                required
                disabled={inputDisabled}
              />
              <label
                htmlFor=""
                style={{
                  top: isFocus ? "-0px" : "22px",
                  fontSize: isFocus ? "12px" : "16px",
                  background: "#153164",
                  color:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "#00FF14"
                      : isFocus
                      ? "4DDD37"
                      : "#dadada",
                }}
              >
                Reason*
              </label>
            </div>
            <div className="note-text">
              <p
                className="w-full h-[19px] font-sans text-[#DADADA] text-[14px] leading-[19px] flex-none order-1"
                style={{
                  marginTop: isMobile ? "15px" : "",
                  marginLeft: isMobile ? "4.5%" : "5.5%",
                }}
              >
                Note - The reason must consist of at least 10 characters.
              </p>
            </div>
            <div className="benefbtnconfandCan">
              {cancleButtonHide && (
                <button
                  id="cancelBtn"
                  onClick={() => {
                    setRejectTxnPopup(false);
                    onClose();
                  }}
                  className={`cancleBtn ${showTransitionClass}`}
                >
                  Cancel
                </button>
              )}
              {showLoader ? (
                <button className="bene-require-expentraLoaderclass">
                  <img src={xpentraloader} alt="Loading..." />
                </button>
              ) : (
                <button
                  className={`${
                    declineReason.replace(/\s+/g, "").length < 10
                      ? "confirmBtn"
                      : "confirm-btn-enable"
                  }`}
                  onClick={(e) => {
                    if (
                      services?.find(
                        (service) =>
                          service === "ABeneficiaryTxn" ||
                          service === "Admin" ||
                          service === "AInternalTxn"
                      ) !== undefined
                    ) {
                      if (
                        (e.type === "click" || e.key === "Enter") &&
                        declineReason.replace(/\s+/g, "").length >= 10
                      ) {
                        if (
                          status === "Transaction" ||
                          sideDrawer === "SideDrawer"
                        ) {
                          BeneTxnDeclinedPopUp(accountId, declineReason);
                        } else {
                          handleRejectTransactionChecker(
                            accountId,
                            declineReason
                          );
                        }
                      }
                    } else {
                      Navigate("/feature_not_assigned", {
                        state: {
                          unavailableService: "ABeneficiaryTxn",
                        },
                      });
                    }
                  }}
                  disabled={
                    inputDisabled ||
                    declineReason.replace(/\s+/g, "").length < 10
                  }
                  style={{
                    cursor:
                      inputDisabled ||
                      declineReason.replace(/\s+/g, "").length < 10
                        ? "default"
                        : "pointer",
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      declineReason.trim().length >= 10
                    ) {
                      e.preventDefault();
                      e.target.click();
                    }
                  }}
                >
                  Reject
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DeclinedPopup;
