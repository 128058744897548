import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import formatDateViewAll from "../../../utils/formatDateViewAll";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import arrowforward from "../../../assets/Beneficiary Icons/arrowForward.png";
import salary from "../../../assets/Beneficiary Icons/salaries.png";
import FormatAmount from "../../_utils/FormatAmount/FormatAmount";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import confidential_transactionImg from "../../../assets/GeneralIcons/confidential_transaction.svg";

const InternalTxnListViewCard = ({
  data,
  isSidebarOpen,
  page,
  numberOfPages,
  isDistributionPopoverOpen,
  isPopoverOpen,
  currentStatus,
  txnHistorytype,
  onClose,
}) => {
  const [columns, setColumns] = useState(3);
  const [cardWidth, setCardWidth] = useState("425px");
  const navigate = useNavigate();

  const listdata = data || [];
  // const maxColumn = Math.floor(window.innerWidth / 480);
  // const column = Math.ceil(listdata.length / maxColumn) + 1;
  // const mappColumn = Math.min(maxColumn, column);
  useEffect(() => {
    const updateLayout = () => {
      const width = window.innerWidth;
      let newColumns;
      let newCardWidth;

      if (width >= 3840) {
        newColumns = 5;
        newCardWidth = "720px";
      } else if (width >= 2560) {
        newColumns = txnHistorytype === "ownerDetailTxnHistory" ? 3 : 4;
        newCardWidth = "600px";
      } else if (width >= 1920) {
        newColumns = 3;
        newCardWidth = "425px";
      } else {
        newColumns = 3;
        newCardWidth = "425px";
      }

      setColumns(newColumns);
      setCardWidth(newCardWidth);
    };

    updateLayout();

    window.addEventListener("resize", updateLayout);

    return () => window.removeEventListener("resize", updateLayout);
  }, []);
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#B196FA";
      case "denied":
      case "failed":
        return "#ff5860";
      case "abandoned":
        return "#a3a3a3";
      case "rejected":
        return "#ff5860";
      case "hold":
        return "#FBC02D";
      case "queued":
        return "#FBC02D";
      default:
        return "#4ddd37";
    }
  };

  const getDistriStatusColor = (status) => {
    switch (status) {
      case "awaiting_verification":
      case "awaiting_funds":
      case "distribution_initiated":
      case "created":
      case "distribution_pending":
      case "approved":
        return "#FBC02D";
      case "denied":
      case "failed":
      case "rejected":
        return "#ff5860";
      case "abandoned":
        return "#B4B4B4";
      case "distribution_complete":
        return "#4DDD37";
      default:
        return "#F9F9F9";
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div
      className="internalTxn-list-container"
      style={{
        paddingBottom:
          txnHistorytype === "ownerDetailTxnHistory"
            ? "150px"
            : txnHistorytype === "internalTxnHistory"
            ? "135px"
            : "108px",
        display: "grid",
        gridTemplateColumns: isMobile
          ? "repeat(1, 1fr)"
          : `repeat(${columns}, 1fr)`,
        columnGap: "30px",
        rowGap: "5px",
        opacity:
          isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
            ? "0.4"
            : "1",
        background:
          isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
            ? "#000"
            : "",
      }}
    >
      {listdata.map((item, index) => (
        <div
          key={item.id}
          style={{
            // width: cardWidth,
            height: "49px",
            margin: "3px",
            cursor:
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "default"
                : "pointer",
            pointerEvents:
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "none"
                : "",
            opacity:
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "0.8"
                : "1",
            background:
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "#000"
                : "",
          }}
        >
          <div
            onClick={() => {
              if (txnHistorytype === "internalTxnHistory") {
                onClose();
                navigate(
                  `/transaction/internal_transaction/details/${item.txn_id}`
                );
              } else {
                let selection = window.getSelection();
                if (!isMobile) {
                  if (selection.toString().length === 0) {
                    if (!isSidebarOpen) {
                      navigate(
                        `/transaction/internal_transaction/details/${item.txn_id}`
                      );
                    }
                  }
                } else {
                  // toast.info("Coming Soon...", toast_position);
                  navigate(
                    `/transaction/internal_transaction/details/${item.txn_id}`
                  );
                }
              }
            }}
            style={{
              opacity:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "0.8"
                  : "1",
              background:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "#000"
                  : "",
              cursor:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "default"
                  : "pointer",
              pointerEvents:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "none"
                  : "",
            }}
            className={`internalTxn_list_section relative hover:bg-[#506994] ${
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "disabled"
                : ""
            }`}
          >
            <div className="internalTxn_listview_bank_div_img">
              <img
                src={require("../../../assets/IfscLogo/" +
                  IfscCodeMapper(item.owner_account_ifsc_code))}
                alt="bank"
                //   className="beneTxn_viewall_paymentmode_image"
              />
            </div>
            <div className="internalTxn_list_accounts_div font-normal overflow-hidden whitespace-nowrap overflow-ellipsis text-[#F9F9F9]">
              <div className="internalTxn_listviewall_bank_acc_div">
                <div className="internalTxn_listViewall">
                  <span>
                    {" "}
                    {" ••••  " +
                      item?.owner_account_bank_acc_no?.substring(
                        item?.owner_account_bank_acc_no?.length - 4
                      )}
                  </span>
                  <p
                    className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                    style={{
                      font: "normal normal normal 10px/14px Open Sans",
                    }}
                  >
                    <span>Source a/c</span>
                  </p>
                </div>

                <img
                  src={arrowforward}
                  className="internalTxn_listviewall_arrow_icon"
                />
                <div className="internalTxn_listViewall">
                  <span
                    style={{
                      textAlign: "end",
                    }}
                  >
                    {" "}
                    {" ••••  " +
                      item?.beneficiary_account_bank_acc_no?.substring(
                        item?.beneficiary_account_bank_acc_no?.length - 4
                      )}
                  </span>
                  <p
                    className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                    style={{
                      font: "normal normal normal 10px/14px Open Sans",
                      textAlign: "end",
                    }}
                  >
                    <span>Destination a/c</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="internalTxn_listview_bankRight_div_img">
              <img
                src={require("../../../assets/IfscLogo/" +
                  IfscCodeMapper(item?.beneficiary_account_ifsc_code))}
                alt="bank"
                //   className="beneTxn_viewall_paymentmode_image"
              />
            </div>

            <div className="internalTxn_list_amount_div flex flex-row items-center justify-end">
              {/* <div>
                {item?.confidential_transaction === "Y" ? (
                  <div className=" bg-[#314d7e]">
                    <img
                      className="w-[20px] h-[20px]"
                      src={confidential_transactionImg}
                      alt="confidential"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div> */}

              <div className="flex flex-col w-full">
                <div
                  className="whitespace-nowrap text-[#4DE659]"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    padding: "0px 8px 0px 0px",
                    font: "normal normal normal 12px/17px Open Sans",
                  }}
                >
                  <div className="">
                    ₹{" "}
                    <span>{<FormatAmount price={Number(item?.amount)} />}</span>
                  </div>
                </div>

                <div
                  className="font-normal pt-1 text-[10px] leading-[10px] text-custom-subheading opacity-100 h-[16px]"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 8px 0px 0px",
                    // font: "normal normal normal 10px/14px Open Sans" ,
                  }}
                >
                  <div>
                    {item?.confidential_transaction === "Y" ? (
                      <div className=" bg-custom-lightBlue">
                        <img
                          className="w-[16px] h-[16px]"
                          src={confidential_transactionImg}
                          alt="confidential"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex items-end">
                    <span>Amount</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="internalTxn_viewallList_hover_content">
              <div className="internalTxn_list_status_div font-normal overflow-hidden whitespace-nowrap overflow-ellipsis text-[#F9F9F9]">
                <p
                  className="overflow-hidden whitespace-nowrap overflow-ellipsis capitalize"
                  style={{
                    padding: "0px 3px 0px 8px",
                    font: "normal normal normal 12px/17px Open Sans",
                    color:
                      item.lifecycle_status === "N/A"
                        ? "#F9F9F9"
                        : getStatusColor(item.lifecycle_status),
                  }}
                >
                  <span>
                    {" "}
                    {currentStatus === "require approval"
                      ? "Require approval"
                      : item.lifecycle_status || "N/A"}
                  </span>
                </p>
                <p
                  className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                  style={{
                    padding: "2px 8px",
                    font: "normal normal normal 10px/14px Open Sans",
                  }}
                >
                  <span>Approval status</span>
                </p>
              </div>
              <div className="internalTxn_list_distribution_div">
                <p
                  className="overflow-hidden whitespace-nowrap overflow-ellipsis capitalize"
                  style={{
                    padding: "0px 3px 0px 8px",
                    justifyContent: "end",
                    font: "normal normal normal 12px/17px Open Sans",
                    color:
                      item.distribution_lifecycle_status === "N/A"
                        ? "#F9F9F9"
                        : getDistriStatusColor(
                            item.distribution_lifecycle_status
                          ),
                  }}
                >
                  <span>
                    {" "}
                    {
                      <>
                        {item.distribution_lifecycle_status === "created"
                          ? "Distribution created"
                          : item.distribution_lifecycle_status ===
                            "awaiting_funds"
                          ? "Awaiting funds"
                          : item.distribution_lifecycle_status ===
                            "distribution_initiated"
                          ? "Distribution initiated"
                          : item.distribution_lifecycle_status === "abandoned"
                          ? "Distribution abandoned"
                          : item.distribution_lifecycle_status === "rejected"
                          ? "Distribution rejected"
                          : item.distribution_lifecycle_status === "failed"
                          ? "Distribution failed"
                          : item.distribution_lifecycle_status ===
                            "distribution_complete"
                          ? "Distribution complete"
                          : item.distribution_lifecycle_status === "approved"
                          ? "Distribution approved"
                          : item.distribution_lifecycle_status || "N/A"}
                      </>
                    }
                  </span>
                </p>
                <p
                  className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                  style={{
                    padding: "2px 8px",

                    font: "normal normal normal 10px/14px Open Sans",
                  }}
                >
                  <span>Distribution status</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InternalTxnListViewCard;
