import React, { useEffect, useState } from "react";
import "./index.css";
import { Button } from "@mui/material";
import {
  beneTxnOwnerAccounts,
  resetBeneOwnerData,
  beneTxnOwnerAccountsNonActive,
  resetBeneOwnerDataNonActive,
} from "../../../redux/features/beneficiary/beneTxnAccounts/beneTransactionAccounts";
import { useDispatch, useSelector } from "react-redux";
import Holder from "../../../assets/UserProfileIcons/select_account_orange.svg";
import Edit from "../../../assets/EditIcons/edit_orange.svg";
import BankReload from "../../../assets/RefreshIcons/reload-refresh_white.svg";
import {
  resetOwnerBalance,
  getCreateTransactionBalance,
} from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import BalanceLoader from "../../_utils/BalanceLoader";
import { Tooltip, tooltipClasses } from "@mui/material";
import RefreshBalanceToast from "../../_utils/RefreshBalanceToast";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import FormatAmount from "../../_utils/FormatAmount/FormatAmount";
import { checkUsertoken } from "../../../redux/features/login/login";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import AnimationUpAndDown from "../OwnerPopup/AnimationUpAndDown";
import OwnerAccountPopUp from "../OwnerAccountPopUp";
import { resetToggle } from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import ConfidentailImage from "../../../assets/GeneralIcons/confidential_transaction.svg";
import { useNavigate } from "react-router-dom";

const BeneTxnSettleMethod = ({
  filterPayload,
  txnPayLoad,
  setTxnPayLoad,
  isNeft,
  setIsNeft,
  isRtgs,
  setIsRtgs,
  isImps,
  setIsImps,
  transactionFilterData,
  setTransactionFilterData,
  setRefreshId,
  BenEnableNextButton,
  openOwner,
  setOpenOwner,
  filterPayloadNonActive,
  isConfidential,
  setIsConfidential,
}) => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.refreshToken.services);
  const navigate = useNavigate();

  const { beneOwnerData } =
    useSelector((state) => state.beneTransactionAccounts) || {};

  const {
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
    createTransactionBankRefreshData,
  } = useSelector((state) => state.ownerAllVerification);

  useEffect(() => {
    dispatch(resetBeneOwnerData());
    dispatch(checkUsertoken());
    dispatch(
      beneTxnOwnerAccounts({
        page_size: 16,
        page_no: filterPayload?.currentPage,
        bank_name: filterPayload?.bankNameInput || undefined,
        sort_by_balance: filterPayload?.sortAccountNo || undefined,
        search_string: filterPayload?.accNoInput,
      })
    );
  }, [
    dispatch,
    filterPayload?.bankNameInput,
    filterPayload?.currentPage,
    filterPayload?.accNoInput,
    filterPayload?.sortAccountNo,
  ]);

  useEffect(() => {
    dispatch(resetBeneOwnerDataNonActive());
    dispatch(checkUsertoken());
    dispatch(
      beneTxnOwnerAccountsNonActive({
        page_size: 16,
        page_no: filterPayloadNonActive?.currentPage,
        bank_name: filterPayloadNonActive?.bankNameInput || undefined,
        sort_by_balance: filterPayload?.sortAccountNo || undefined,
        search_string: filterPayloadNonActive?.accNoInput,
      })
    );
  }, [
    dispatch,
    filterPayloadNonActive?.bankNameInput,
    filterPayloadNonActive?.currentPage,
    filterPayloadNonActive?.accNoInput,
    filterPayload?.sortAccountNo,
  ]);

  useEffect(() => {
    setTransactionFilterData(beneOwnerData?.owner_accounts);
  }, [beneOwnerData]);

  const [slideAnimation, setSlideAnimation] = useState(false);

  const [balanceRefresh, setBalanceRefresh] = useState(false);

  const [balanceRefreshDisabled, setBalanceRefreshDisabled] = useState(false);

  const [refreshAnimationId, setRefreshAnimationId] = useState(null);
  const [previousRefreshData, setPreviousRefreshData] = useState(null);
  const [refreshAll, setRefreshAll] = useState(false);
  const [refreshType, setRefreshType] = useState(false);

  const openOwnerAccountPopup = () => {
    dispatch(resetToggle());
    setRefreshAnimationId("");
    setOpenOwner(true);
  };

  useEffect(() => {
    if (createTransactionBankRefreshLoading) {
      toast.dismiss();
      RefreshBalanceToast();
    } else if (createTransactionBankRefreshData?.status) {
      setBalanceRefresh(true);
      toast.dismiss();
      toast.success("Balance refreshed successfully", toast_position);
      setTimeout(() => {
        setBalanceRefresh(false);
        setBalanceRefreshDisabled(false);
      }, 1000);
    } else if (
      createTransactionBankRefreshError &&
      !createTransactionBankRefreshLoading
    ) {
      toast.dismiss();
      toast.error(createTransactionBankRefreshError, toast_position);
    }
    if (createTransactionBankRefreshData) {
      let data = transactionFilterData.map((val) => {
        if (
          val.id ===
          createTransactionBankRefreshData?.owner_accounts[0]?.owner_account_id
        ) {
          return {
            ...val,
            owner_account_balance:
              createTransactionBankRefreshData?.owner_accounts[0]
                ?.owner_account_balance,
            owner_account_balance_last_fetched_at_in_words:
              createTransactionBankRefreshData?.owner_accounts[0]
                ?.owner_account_balance_last_fetched_at_in_words,
          };
        }
        return val;
      });
      setTransactionFilterData(data);
      setTxnPayLoad({
        ...txnPayLoad,
        ownerBankBalance:
          createTransactionBankRefreshData?.owner_accounts[0]
            ?.owner_account_balance,
        ownerLastUpdated:
          createTransactionBankRefreshData?.owner_accounts[0]
            ?.owner_account_balance_last_fetched_at_in_words,
      });
    }
    return () => {
      dispatch(resetOwnerBalance());
    };
  }, [
    createTransactionBankRefreshData,
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
  ]);

  const handleRefresh = (e, id, prevBalance) => {
    if (services?.includes("VAccountBalance") || services?.includes("Admin")) {
      setBalanceRefreshDisabled(true);
      setPreviousRefreshData(prevBalance);
      setRefreshType("single");
      e.stopPropagation();
      setRefreshId(id);
      setRefreshAnimationId(id);
      dispatch(checkUsertoken());
      dispatch(getCreateTransactionBalance(id));
      setTimeout(() => {
        setBalanceRefreshDisabled(false);
      }, 1000);
    } else {
      e.stopPropagation();
      navigate("/feature_not_assigned");
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const ConfidentialTxn = () => {
    return (
      <div
        className="right_side_select_ownerBene_div_top_res"
        style={{
          width: !isMobile ? "100%" : "",
        }}
      >
        <div className="right_side_select_owner_div_top_img_res rounded-tr-[10px] rounded-bl-[10px]">
          <img src={ConfidentailImage} alt="confidential" />
        </div>
        <div className="flex flex-row items-center w-[100%] justify-center">
          <div className="right_side_select_owner_div_top_para">
            <h3
              style={{
                width: "auto",
                height: "auto",
                paddingBottom: "5px",
                color: "#DADADA",
              }}
            >
              Create As Confidential Transaction
            </h3>
            <p
              style={{
                width: "auto",
                height: "auto",
              }}
            >
              Enabling confidential transaction will make this transaction
              visible to only those users who have the feature to view
              confidential transactions.
            </p>
          </div>
          <div
            className="right_side_select_owner_div_top_button"
            onClick={() => {
              setIsConfidential(!isConfidential);
            }}
            style={{
              backgroundColor: isConfidential ? "#69B76F" : "#707070",
            }}
          >
            <button
              className="right_side_select_owner_div_top_button_inner"
              style={{
                transform: isConfidential
                  ? "translateX(18px)"
                  : "translateX(0px)",
              }}
            ></button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div className="beneSettle_Main">
      {openOwner && (
        <OwnerAccountPopUp
          setOpenOwner={setOpenOwner}
          setIsNeft={setIsNeft}
          setIsImps={setIsImps}
          setIsRtgs={setIsRtgs}
          txnPayLoad={txnPayLoad}
          setTxnPayLoad={setTxnPayLoad}
          BenEnableNextButton={BenEnableNextButton}
          flow={"createTxn"}
        />
      )}
      {isMobile ? ConfidentialTxn() : null}

      <div
        className="settle_main_div_container"
        style={{
          // backgroundColor:
          //   txnPayLoad?.ownerBankAccountNo === null ? "transparent" : "#314d7e",
          // boxShadow:
          //   txnPayLoad?.ownerBankAccountNo === null
          //     ? "none"
          //     : "0px 3px 6px #00000029",
          width: isMobile ? `${windowWidth}px` : "",
        }}
      >
        <div
          className="relative"
          style={{
            backgroundColor:
              txnPayLoad?.ownerBankAccountNo === null
                ? "transparent"
                : "#314d7e",
            boxShadow:
              txnPayLoad?.ownerBankAccountNo === null
                ? "none"
                : "0px 3px 6px #00000029",
            width: "100%",
            height: "inherit",
            borderRadius: "10px",
          }}
        >
          <div
            className="settle_main_div_container_img"
            style={{
              display:
                txnPayLoad?.ownerBankAccountNo === null ? "none" : "flex",
            }}
          >
            <img
              src={require("../../../assets/IfscLogo/" +
                IfscCodeMapper(txnPayLoad.ownerBankLogo))}
              alt="bank"
            />
          </div>
          <div
            className="settle_main_div"
            style={{ width: isMobile ? `inherit` : "" }}
          >
            <div
              className="select_owner_box"
              style={{ width: isMobile ? `inherit` : "" }}
            >
              <div className="select_tag_name">
                <p className="source_select_p1">Source Account Details</p>
                <p className="source_select_p2">
                  This account will be used to settle this transaction once it
                  is approved.
                </p>
              </div>
              <Button
                className="select_owner_butt"
                onClick={openOwnerAccountPopup}
              >
                <img
                  src={Holder}
                  alt="select user"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                    display:
                      txnPayLoad?.ownerBankAccountNo === null
                        ? "block"
                        : "none",
                  }}
                />
                <span
                  style={{
                    color: "#FF7F33",
                    fontSize:
                      txnPayLoad?.ownerBankAccountNo === null ? "16px" : "20px",
                  }}
                >
                  {txnPayLoad?.ownerBankAccountNo === null
                    ? "Select owner account"
                    : `${
                        txnPayLoad?.ownerBankAccountNo
                          ?.toString()
                          ?.substring(0, 4) ?? ""
                      } •••• ${
                        txnPayLoad?.ownerBankAccountNo
                          ?.toString()
                          ?.substring(
                            txnPayLoad?.ownerBankAccountNo?.length - 4
                          ) ?? ""
                      }`}
                </span>
                <img
                  src={Edit}
                  alt="select user"
                  style={{
                    width: "16px",
                    height: "16px",
                    marginLeft: "15.73px",
                    display:
                      txnPayLoad?.ownerBankAccountNo === null
                        ? "none"
                        : "block",
                  }}
                />
              </Button>
            </div>
          </div>
          <div
            className="settle_main_div_inner_con"
            style={{
              display:
                txnPayLoad?.ownerBankAccountNo === null ? "none" : "flex",
            }}
          >
            <AnimationUpAndDown
              loader={createTransactionBankRefreshLoading}
              currentId={txnPayLoad?.ownerId}
              previousId={refreshAnimationId}
              currentBalance={txnPayLoad?.ownerBankBalance}
              previousBalance={previousRefreshData}
              refreshType={refreshType}
              classType={"beneOwner"}
            />
            <div className="settle_main_div_inner_con_amt">
              <p className="settle_main_div_inner_con_amt_1">
                {" "}
                ₹ {<FormatAmount price={txnPayLoad.ownerBankBalance} />}
              </p>
              <p className="settle_main_div_inner_con_amt_2">
                {`Balance updated ${txnPayLoad.ownerLastUpdated}`}
              </p>
            </div>

            <Tooltip
              componentsProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: (theme) => theme.palette.common.black,
                    },
                  },
                },
              }}
              title="Refresh Balance"
            >
              <button
                disabled={balanceRefreshDisabled}
                className={`bank_amt_div_refresh_settle ${
                  services?.includes("VAccountBalance") ||
                  services?.includes("Admin")
                    ? "cursor-pointer"
                    : "cursor-not-allowed opacity-50"
                }`}
                onClick={(e) =>
                  handleRefresh(
                    e,
                    txnPayLoad?.ownerId,
                    txnPayLoad?.ownerBankBalance
                  )
                }
              >
                {createTransactionBankRefreshLoading ? (
                  <BalanceLoader
                    loader={createTransactionBankRefreshLoading}
                    type={"createTxn"}
                  />
                ) : balanceRefresh ? (
                  <BalanceLoader
                    loader={createTransactionBankRefreshLoading}
                    setAccId={setRefreshId}
                    type={"createTxn"}
                  />
                ) : (
                  <img src={BankReload} alt="reload" />
                )}
              </button>
            </Tooltip>
          </div>
          <div
            className="settle_main_div_ifsc"
            style={{
              display:
                txnPayLoad?.ownerBankAccountNo === null ? "none" : "block",
            }}
          >
            <h6>{txnPayLoad.ownerBankIFSC}</h6>
            <p>IFSC code</p>
          </div>
        </div>
      </div>
      <div
        className="settleType_main_div"
        style={{
          // visibility:
          //   txnPayLoad?.ownerBankAccountNo === null ? "hidden" : "visible",
          // overflow: "hidden",
          background: "transparent",
          width: isMobile ? `${windowWidth}px` : "",
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
        }}
      >
        {!isMobile ? ConfidentialTxn() : null}

        <div
          style={{
            visibility:
              txnPayLoad?.ownerBankAccountNo === null ? "hidden" : "visible",
            overflow: "hidden",
            background: "#314d7e 0% 0% no-repeat padding-box",
            width: "100%",
            height: "inherit",
            borderRadius: "20px",
            marginTop: "20px",
          }}
        >
          <p className="text-[#f9f9f9] p-[20px] pb-0">Select Payment Method</p>
          <div className="settleType_neft_imps_rfgs_container">
            <div
              className="settleType_neft_imps_rfgs_container_back"
              style={{
                display: isNeft || isImps || isRtgs ? "block" : "none",
                left: isMobile
                  ? isRtgs
                    ? "50%"
                    : "0px"
                  : isRtgs
                  ? "236px"
                  : "0px",
              }}
            ></div>
            <Button
              className="settleType_neft_imps_rfgs_container_inner"
              onClick={() => {
                if (!isNeft) {
                  setSlideAnimation(true);
                }
                setTimeout(() => {
                  setSlideAnimation(false);
                }, [100]);
                setIsNeft(true);
                setIsImps(false);
                setIsRtgs(false);
                setTxnPayLoad({ ...txnPayLoad, settleType: "neft" });
                BenEnableNextButton(false);
              }}
            >
              <h6>NEFT</h6>
              <p style={{ color: isNeft ? "#F9F9F9" : "#DADADA" }}>
                Upto 30 mins transfer during banking hours
              </p>
            </Button>
            <Button
              className="settleType_neft_imps_rfgs_container_inner settleType_rfgs_inner"
              onClick={() => {
                if (!isRtgs) {
                  setSlideAnimation(true);
                }
                setTimeout(() => {
                  setSlideAnimation(false);
                }, [100]);
                setIsNeft(false);
                setIsImps(false);
                setIsRtgs(true);
                setTxnPayLoad({ ...txnPayLoad, settleType: "rtgs" });
              }}
            >
              <h6>RTGS</h6>
              <p style={{ color: isRtgs ? "#F9F9F9" : "#DADADA" }}>
                Instant transfer during banking hours
              </p>
            </Button>
          </div>
          <div className="settleType_neft_imps_rfgs_container_info">
            {isImps ? (
              <div
                className="neft_container_info"
                style={{
                  animation:
                    isImps && slideAnimation
                      ? "slideFromRight 0.4s ease forwards"
                      : "",
                }}
              >
                <h6>IMPS</h6>
                <div className="neft_container_info_bullet">
                  <p>• Transactions executed every 30 mins upon approval</p>
                  <p>• Maximum transaction limit ₹ 2 Lakhs</p>
                  <p>• Additional charges are applicable</p>
                </div>
              </div>
            ) : isRtgs ? (
              <div
                className="neft_container_info"
                style={{
                  animation:
                    isRtgs && slideAnimation
                      ? "slideFromRight 0.4s ease forwards"
                      : "",
                }}
              >
                <h6>RTGS</h6>
                <div className="neft_container_info_bullet">
                  <p>• Transactions executed instantly upon approval</p>
                  <p>• Minimum transaction limit ₹ 2 Lakhs</p>
                  <p>• Additional charges are applicable</p>
                </div>
              </div>
            ) : isNeft ? (
              <div
                className="neft_container_info"
                style={{
                  animation:
                    isNeft && slideAnimation
                      ? "slideFromRight 0.4s ease forwards"
                      : "",
                }}
              >
                <h6>NEFT</h6>
                <div className="neft_container_info_bullet">
                  <p>• Transactions executed every 30 mins upon approval</p>
                  <p>• Additional charges are applicable</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneTxnSettleMethod;
