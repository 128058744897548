const ServiceIDByLabel = {
  // "InstantPay Refill": "VInstantBalance",
  // "Vendor Transactions": "VVendorTxn",
  "Internal Transaction": "VInternalTxn",
  // "Client Transactions": "VSinglePay",
  // "Field Force Transactions": "FVTransaction",
  // "Reimbursement Transactions": "RVTransaction",
  // "Income Transactions": "VDIncome",
  // "Nodal Transactions": "VDNodal",
  // "Adjustment Transactions": "",
  // "Chargeback Transactions": "VChargeback",
  // "Duplicate Transactions": "",
  "Beneficiary Transaction": "VBeneficiaryTxn",

  // "InstantPay": "ViewMaintenanceAccount",
  // "Vendor Accounts": "VAccount",
  "Owner Accounts": "VAccount",
  // "Client Accounts": "VAccount",
  // "Field Force Accounts": "FVAccount",
  // "Cards Accounts": "VCard",
  // "InstantPay Accounts": "VInstantTxn",
  "Beneficiary Accounts": "BVAccount",
  "Internal Accounts": "BVAccount",
};

export default ServiceIDByLabel;
