import "./StepperTxn.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { internalTransactionCreate } from "../../../redux/features/internalTransaction/internalTransactionCreate";
import { checkUsertoken } from "../../../redux/features/login/login";
import { resetViewAllAcc } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { useState, useEffect } from "react";
import { uploadImages } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";

const StepperTxn = ({
  activeStep,
  handleBack,
  steps,
  handleNext,
  nextButtonEnabled,
  direction,
  payload,
  isConfidential,
  files,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const services = useSelector((state) => state.refreshToken.services);
  const [disabled, setDisabled] = useState(false);
  const handleCreateBiller = async () => {
    setDisabled(true);
    let haveImage = payload?.mergeCategory?.some((val) => {
      return val?.data_type === "upload" && val?.value !== "Not Given";
    });
    let internalData = {
      amount: payload?.amount,
      owner_account_id: payload?.ownerId,
      beneficiary_account_id: payload?.beneAccountId,
      payment_method: payload?.settleType?.toUpperCase(),
      category: payload?.selectedCategory?.name,
      confidential_transaction: isConfidential,
    };

    if (payload?.mergeCategory?.length !== 0) {
      if (haveImage) {
        const responseUpload = await dispatch(uploadImages(files));
        if (responseUpload) {
          internalData.additional_data = {
            data: payload?.mergeCategory?.map((fil) => {
              if (
                fil?.value === "Not Given" ||
                fil?.value === "" ||
                (fil.data_type === "quantity" && fil.value <= 0)
              ) {
                const { value, ...rest } = fil;
                return rest;
              } else if (fil?.data_type === "upload") {
                const { description, ...rest } = fil;
                return { ...rest, value: responseUpload?.payload };
              } else {
                return fil;
              }
            }),
          };
        }
      } else {
        internalData.additional_data = {
          data: payload?.mergeCategory?.map((fil) => {
            if (
              fil?.value === "Not Given" ||
              fil?.value === "" ||
              (fil.data_type === "quantity" && fil.value <= 0)
            ) {
              const { value, ...rest } = fil;
              return rest;
            } else {
              return fil;
            }
          }),
        };
      }
    }

    dispatch(checkUsertoken());
    dispatch(internalTransactionCreate({ services, navigate, internalData }));
    dispatch(resetViewAllAcc());
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div
      className="internal-stepper-main"
      style={{ width: `${windowWidth}px`, maxWidth: "1024px", padding: "0px 10px" }}
    >
      <Button
        disabled={activeStep === 0}
        className={`custom_create ${activeStep === 0 ? "inactive" : "active"}`}
        style={{
          backgroundColor: "#506994",
          color: "#FFFFFF",
          opacity: activeStep === 0 ? "0.4" : "1",
        }}
        sx={{ borderRadius: 0 }}
        onClick={handleBack}
      >
        Previous
      </Button>
      <div className="stepperline_Div_txn">
        {steps?.map((val, index) => {
          return (
            <div className="stepinnerline" key={index}>
              <div
                className={`stepDetails ${activeStep !== index ||
                    (activeStep === 0 && direction === "next")
                    ? ""
                    : isMobile
                      ? "growing-element"
                      : "internal-growing-element"
                  }`}
                style={{
                  opacity: activeStep === index ? "1" : "0",
                }}
              >
                {activeStep === index ? val : "Hello"}
              </div>
              <div
                className={`main_Line ${activeStep !== index ||
                    (activeStep === 0 && direction === "next")
                    ? ""
                    : isMobile
                      ? "growing-element"
                      : "internal-growing-element"
                  }`}
                style={{
                  width: isMobile
                    ? activeStep === index
                      ? "100%"
                      : "50%"
                    : activeStep === index
                      ? "360px"
                      : "100px",
                  opacity: activeStep === index ? "1" : "0.5",
                }}
              ></div>
            </div>
          );
        })}
      </div>
      {activeStep === 2 ? (
        <Button
          className={`create_Button ${activeStep === 2 ? "active" : "inactive"
            }`}
          onClick={handleCreateBiller}
          sx={{ borderRadius: 0 }}
          disabled={disabled}
        >
          Create
        </Button>
      ) : (
        <Button
          disabled={!nextButtonEnabled}
          className={`custom_create_next ${!nextButtonEnabled ? "inactive" : "active"
            }`}
          style={{
            backgroundColor: !nextButtonEnabled ? "#656565" : "#F9F9F9",
            color: !nextButtonEnabled ? "#FFFFFF" : "#1D3A6D",
          }}
          sx={{ borderRadius: 0 }}
          onClick={handleNext}
        >
          Next
        </Button>
      )}
    </div>
  );
};

export default StepperTxn;
